import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import Loading from "../Components/Loading";
import Select from "react-select";
import { setDivision } from "../Redux/Action/division";
import LoadingSpinner from "../Components/Loader/UploadLoader";

const PettyCash = () => {
  const dispatch = useDispatch();
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_REPORTS = process.env.REACT_APP_BASEURL_REPORTS;
  const divisionState = useSelector((state) => state.changeDivision);
  const [division, setdivsion] = useState(divisionState);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const userState = useSelector((state) => state.changeTheState.user);
  const email = userState?.emailId;
  const [isoading, setisloading] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [divisionList, setDivisionList] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [accountCode, setAccountCode] = useState("");
  const [expaccountCode, setexpAccountCode] = useState("");
  const [expenseAccountList, setExpenseAccountList] = useState([]);
  const [docNoList, setDocNoList] = useState([]);
  const [search, setSearch] = useState("");
  const currenttDate = new Date();
  const startOfYear = new Date(currenttDate.getFullYear(), 0, 2);
  const [startdate, setstartdate] = useState(startOfYear.toISOString());
  const [enddate, setEnddate] = useState(new Date().toISOString());
  const [userdivisionRights, setuserDivisionList] = useState([]);

  const [pdfData, setPdfData] = useState(null);
  const [LoadingScreenBlock, setLoadingScreenBlock] = useState(false);
  const [hist, setHist] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportPath, setReportPath] = useState("");

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?OM_USER_ACCOUNT=${email}`)
      .then((response) => {
        setuserDivisionList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [formObject, setFormObject] = useState({
    VOUCHERNO: 0,
    DOCNO: 0,
    ACCOUNTCODE: "",
    AMOUNT: 0,
    DOCDATE: new Date().toISOString().split("T")[0],
    REFNO: "",
    ACCOUNTNAME: "",
    NARRATION: "",
    USERID: userState.userCode,
    DIVISION: division,
    DISPLAYACCNAME: "",
    EXPENSEACCOUNTCODE: "",
    EXPENSEACCOUNTNAME: "",
  });
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "200px",
      maxWidth: "200px",
      marginTop: "0px",
      minHeight: "initial",
      height: "30px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  function handleDataChange(e) {
    const { name, value, type, files } = e.target;
    setFormObject({ ...formObject, [name]: value });
  }

  function getInitialData() {
    const date = new Date().toISOString();
    setCurrentDate(date);
    setisloading(true);
    axios
      .get(`${BASEURL}/MTVWGETNEWVOUCHERNO`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        formObject.VOUCHERNO = response.data[0].LastVocNo;
        axios
          .get(`${BASEURL}/MTVWGETNEWJOURNALNO`, {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          })
          .then((response) => {
            formObject.DOCNO = response.data[0].LastVocDocNo;
            setisloading(false);
          })
          .catch((err) => {
            console.log(err);
            setisloading(false);
          });

        axios
          .get(`${BASEURL}/getDivisionList`, {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          })
          .then((response) => {
            setDivisionList(response.data);
          })
          .catch((err) => {
            console.log(err);
            setisloading(false);
          });
        axios
          .get(`${BASEURL}/MTVWEXPENSES`, {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          })
          .then((response) => {
            setExpenseAccountList(response.data);
          })
          .catch((err) => {
            console.log(err);
            setisloading(false);
          });
        axios
          .post(
            `${BASEURL}/getDocNoList`,
            { DIVISIONCODE: division },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setDocNoList(response.data);
          })
          .catch((err) => {
            console.log(err);
            setisloading(false);
          });
        fetchAccountList(division);
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
  }

  useEffect(() => {
    getInitialData();
    getReportName();
  }, []);

  function getReportName() {
    axios
      .get(
        `${BASEURL_REPORTS}/getreportnames?DOCUMENTNAME=Petty Cash Voucher`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setReportName(response?.data[0]?.CRNAME);
        setReportPath(response?.data[0]?.REPORTPATH);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }

  function fetchAccountList(name) {
    axios
      .post(
        `${BASEURL}/MTVWFEG`,
        { DIVISIONLOGGEDIN: name },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setAccountList(response.data);
      })
      .catch((err) => {
        console.log(err);
        setisloading(false);
      });
  }

  async function handleSubmit() {
    if (
      formObject.VOUCHERNO &&
      formObject.DOCNO &&
      formObject.ACCOUNTCODE &&
      formObject.AMOUNT &&
      formObject.DOCDATE &&
      formObject.REFNO &&
      formObject.ACCOUNTNAME &&
      formObject.NARRATION &&
      formObject.USERID &&
      formObject.DIVISION &&
      formObject.EXPENSEACCOUNTCODE &&
      formObject.EXPENSEACCOUNTNAME
    ) {
      try {
        const drResponse = await axios.post(
          `${BASEURL}/insertjournal/dr`,
          {
            VOUCHERNO: formObject.VOUCHERNO,
            DOCNO: formObject.DOCNO,
            ACCOUNTCODE: formObject.EXPENSEACCOUNTCODE,
            AMOUNT: formObject.AMOUNT,
            DOCDATE: formObject.DOCDATE,
            REFNO: formObject.REFNO,
            ACCOUNTNAME: formObject.EXPENSEACCOUNTNAME,
            NARRATION: formObject.NARRATION,
            USERID: formObject.USERID,
            DIVISION: formObject.DIVISION,
            DDRCR: "DR",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        const crResponse = await axios.post(
          `${BASEURL}/insertjournal/cr`,
          {
            VOUCHERNO: formObject.VOUCHERNO,
            DOCNO: formObject.DOCNO,
            ACCOUNTCODE: formObject.ACCOUNTCODE,
            AMOUNT: formObject.AMOUNT,
            DOCDATE: formObject.DOCDATE,
            REFNO: formObject.REFNO,
            ACCOUNTNAME: formObject.ACCOUNTNAME,
            NARRATION: formObject.NARRATION,
            USERID: formObject.USERID,
            DIVISION: formObject.DIVISION,
            DDRCR: "CR",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        alert(crResponse.data.message);
        window.location.reload();
      } catch (err) {
        console.log(err);
        alert(err.response.data.err);
      }
    } else {
      alert("Enter all Data");
    }
  }

  async function fetchDocumentHistory(docno) {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/MTVWJOURNAL`,
        { DOCNO: docno },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );
      // updateObject(formObject, response.data[0]);
      if (response.data[0].CRDR === "DR") {
        formObject.ACCOUNTCODE = response.data[0]?.LEDGERCODE;
        formObject.ACCOUNTNAME = response.data[0]?.FM_ACCOUNT_NAME;
        formObject.EXPENSEACCOUNTCODE = response.data[1]?.LEDGERCODE;
        formObject.EXPENSEACCOUNTNAME = response.data[1]?.FM_ACCOUNT_NAME;
      } else {
        formObject.ACCOUNTCODE = response.data[1]?.LEDGERCODE;
        formObject.ACCOUNTNAME = response.data[1]?.FM_ACCOUNT_NAME;
        formObject.EXPENSEACCOUNTCODE = response.data[0]?.LEDGERCODE;
        formObject.EXPENSEACCOUNTNAME = response.data[0]?.FM_ACCOUNT_NAME;
      }
      // formObject.ACCOUNTCODE = response.data[0].LEDGERCODE;
      formObject.AMOUNT = response.data[0]?.AMOUNT;
      formObject.DIVISION = response.data[0]?.DIVISIONCODE;
      formObject.DOCDATE = response.data[0]?.DOCDATE?.split("T")[0];
      formObject.DOCNO = response.data[0]?.DOCNO;
      formObject.NARRATION = response.data[0]?.NARRATION;
      formObject.REFNO = response.data[0]?.REFNO;
      formObject.VOUCHERNO = response.data[0]?.VOUCHERNO;
      formObject.USERID = response.data[0]?.CREATEDBY;
      // formObject.DISPLAYACCNAME = response.data[0].FM_ACCOUNT_NAME;
      setHist(true);
      setisloading(false);
    } catch (err) {
      setisloading(false);
      console.log(err);
    }
  }

  function updateObject(target, source) {
    for (let key in source) {
      if (source.hasOwnProperty(key) && target.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }
  }

  const filtHistoricCash = docNoList.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.DOCDATE;
    const eDate = item.DOCDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["DOCNO", "VOUCHERNO", "FM_ACCOUNT_NAME"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  // Crystal Reports
  function generateReport() {
    setPdfData(null);

    if (!formObject.DOCNO) {
      alert("Please select a Document No");
      return;
    }
    setLoadingScreenBlock(true);
    axios
      .post(
        `${BASEURL_REPORTS}/getReports`,
        {
          REPORTNAME: reportName,
          DOCNO: Number(formObject.DOCNO),
          REPORTPATH: `${reportPath?.replace(/\\\\/g, "\\")}`,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log(res);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfData(fileURL);
        setLoadingScreenBlock(false);
      })
      .catch((err) => {
        console.log("Error generating report:", err);
        setLoadingScreenBlock(false);
      });
  }

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfData;
    a.download = "generated_report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const renderList = (
    <>
      <div className="bg-slate-100 m-2 h-auto py-2 px-10 w-[98%] rounded mt-4 shadow-lg">
        {/* 1st row */}
        <section className="lg:flex">
          <div className="flex felx-row ml-2">
            <p className="w-[130px]">Division:</p>
            {/* <p className="w-[355px] ">{divisionState}</p>
             */}

            <select
              onChange={(e) => {
                setdivsion(e.target.value);
                dispatch(setDivision(e.target.value));
              }}
              className="w-48 h-6 border-black border ml-2 mr-2 rounded"
            >
              <option>{divisionState}</option>
              {userdivisionRights.map((item) =>
                item.OM_USER_DIVISION !== divisionState ? (
                  <option
                    key={item.OM_USER_DIVISION}
                    value={item.OM_USER_DIVISION}
                  >
                    {item.OM_USER_DIVISION}
                  </option>
                ) : null
              )}
            </select>
            {/* <Select
            name="DIVISION"
            defaultValue={{
              label: formObject.DIVISION,
              value: formObject.DIVISION,
            }}
            onChange={(e) => {
              formObject.DIVISION = e.value;
              fetchAccountList(e.value);
            }}
            styles={customStyless}
            options={divisionList.map((item) => {
              return { label: item.DIVISION, value: item.DIVISION };
            })}
          /> */}
          </div>
          <div className="flex felx-row ml-2">
            <p className="w-[110px] lg:w-[85px]">User ID:</p>
            <input
              value={userState.userCode}
              disabled
              className="w-48 border border-black h-6 ml-5 rounded bg-white"
            ></input>
            <p className="ml-5">Date:</p>
            <input
              className="w-32 border border-black h-6 ml-7 rounded"
              type="date"
              defaultValue={currentDate.split("T")[0]}
              onChange={(e) => {
                setCurrentDate(e.target.value);
                formObject.DOCDATE = e.target.value;
              }}
            ></input>
          </div>
        </section>
        {/* 2nd row */}
        <section className="lg:flex">
          <div className="flex felx-row ml-2 mt-2">
            <p className="w-[130px]">Doc No:</p>
            <div className="w-[355px]">
              <input
                disabled
                defaultValue={formObject.DOCNO}
                className="w-48 border border-black h-6  mr-1 rounded bg-white"
              ></input>
              <button
                onClick={() => setcashsalepopup(true)}
                className="bg-[#002e62dd] border rounded border-black w-6 h-6  text-white"
              >
                ...
              </button>
            </div>

            {cashsalepopup && (
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[700px] lg:w-[900px] p-1 overflow-y-auto">
                <section className="lg:flex">
                  <div className="flex flex-row mt-2">
                    <p className=" ml-2">Search:</p>
                    <input
                      className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      type="text"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    ></input>
                  </div>
                  <div className="flex flex-row mt-2">
                    <p className=" ml-2">Start Date:</p>
                    <input
                      type="date"
                      className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      onChange={(e) => {
                        setstartdate(e.target.value);
                      }}
                      value={startdate.split("T")[0]}
                    ></input>
                    <p className=" ml-2">End Date:</p>
                    <input
                      type="date"
                      className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                      onChange={(e) => {
                        setEnddate(e.target.value);
                      }}
                      value={enddate.split("T")[0]}
                    ></input>
                  </div>
                  <button
                    className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002e62dd] text-white rounded-md px-3 py-1 ml-2 mr-2 hover:bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                    onClick={() => setcashsalepopup(false)}
                  >
                    Close
                  </button>
                </section>
                {/* <aside className="table__pos__app ">
                <h1>PICK UPS DONE AND PENDING ARRIVALS</h1>
                <table class="custom-table">
                  <thead class="header-cell">
                    <td>MODULE&nbsp;ID</td>

                    <td>EDIT</td>
                  </thead>
                  <tbody>
                    <tr class="table-row">
                      <td className="alignleft">1</td>

                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </aside> */}
                <aside className="table__pos__app  text-sm ">
                  <table class="custom-table">
                    <thead class="header-cell">
                      <tr className="bg-blue-900 text-white uppercase text-sm">
                        <td className="pl-2">Doc No</td>
                        <td className="text-left">Voucher no</td>
                        <td className="text-left">DAte</td>
                        <td className="text-left">Amount</td>
                        <td className="text-right">Account Name</td>
                        <td className="text-right">Narration</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filtHistoricCash.map((item) => {
                        return (
                          <tr
                            onClick={() => {
                              fetchDocumentHistory(item.DOCNO);
                              setcashsalepopup(false);
                            }}
                            className={"table-row text-sm"}
                          >
                            <td>{item.DOCNO}</td>
                            <td>{item.VOUCHERNO}</td>
                            <td>
                              {item.DOCDATE
                                ? moment(item.DOCDATE).format("DD/MM/YYYY")
                                : ""}
                            </td>
                            <td>{item.AMOUNT}</td>
                            <td>{item.FM_ACCOUNT_NAME}</td>
                            <td>{item.NARRATION}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </aside>
              </div>
            )}
          </div>
          <div className="flex felx-row ml-2 mt-2">
            <p className="w-[115px] lg:w-[90px]">Voucher No:</p>
            <input
              disabled
              defaultValue={formObject.VOUCHERNO}
              className="w-48 border border-black h-6 ml-[15px] rounded bg-white "
            ></input>
            <p className="ml-4">Amount:</p>
            <input
              name="AMOUNT"
              onChange={(e) => {
                handleDataChange(e);
              }}
              defaultValue={formObject.AMOUNT}
              className="w-48 border border-black h-6 ml-2 mr-1 rounded bg-white"
            ></input>
            {/* <button className="bg-[#002e62dd] text-white rounded border border-black w-6 h-6 ">
              ...
            </button> */}
          </div>
        </section>
        {/* 3rd row */}
        <div className="flex felx-row ml-2 mt-2">
          <p className="w-[130px]">From Account:</p>
          <Select
            name="DIVISION"
            defaultValue={{
              label: formObject.ACCOUNTNAME,
              value: formObject.ACCOUNTNAME,
            }}
            onChange={(e) => {
              formObject.ACCOUNTCODE = e.value;
              formObject.ACCOUNTNAME = e.label;
              setAccountCode(e.value);
            }}
            styles={customStyless}
            options={accountList.map((item) => {
              return { label: item.ACCOUNTNAME, value: item.ACCOUNTCODE };
            })}
          />
          <input
            value={accountCode || formObject.ACCOUNTCODE}
            className="mx-1 w-32 border border-black h-6 rounded"
          ></input>
        </div>
        {/* 4th row */}
        <div className="flex felx-row ml-2 mt-2">
          <p className="w-[130px]">Expense Account:</p>
          <Select
            name="DIVISION"
            defaultValue={{
              label: formObject.EXPENSEACCOUNTNAME,
              value: formObject.EXPENSEACCOUNTNAME,
            }}
            onChange={(e) => {
              formObject.EXPENSEACCOUNTNAME = e.label;
              setexpAccountCode(e.value);
              formObject.EXPENSEACCOUNTCODE = e.value;
            }}
            styles={customStyless}
            options={expenseAccountList.map((item) => {
              return { label: item.ACCOUNTNAME, value: item.ACCOUNTCODE };
            })}
          />
          <input
            value={expaccountCode || formObject.EXPENSEACCOUNTCODE}
            className="mx-1 w-32 border border-black h-6 rounded"
          ></input>
        </div>
        {/* 5th row */}
        <div className="flex felx-row ml-2 mt-2">
          <p className="w-[130px]">Ref No:</p>
          <input
            name="REFNO"
            onChange={(e) => {
              handleDataChange(e);
            }}
            defaultValue={formObject.REFNO}
            className="w-32 border border-black h-6 rounded "
          ></input>
        </div>
        {/* 6th row */}
        <div className="flex flex-row ml-2 mt-2">
          <p className="w-[130px]">Narration:</p>
          <textarea
            name="NARRATION"
            onChange={(e) => {
              handleDataChange(e);
            }}
            defaultValue={formObject.NARRATION}
            className="narration__all border border-black  rounded "
          ></textarea>
          <button
            onClick={handleSubmit}
            className="pl-2 pr-2 bg-[#002e62dd] text-white rounded border border-black w-auto h-8 mr-2 ml-2 mt-10"
          >
            SAVE
          </button>
          <button className="pl-2 pr-2 bg-[#002e62dd] text-white rounded border border-black w-auto h-8 mr-2 mt-10">
            PRINT
          </button>
          <button
            className="pl-2 pr-2 bg-[#002e62dd] text-white rounded border border-black w-auto h-8 mr-2 mt-10"
            onClick={() => {
              if (hist === false) {
                alert("Kindly Save the Petty Cash first");
              } else {
                generateReport();
              }
            }}
          >
            Generate Report
          </button>
        </div>
        {/* 5th row */}
        <div className="flex felx-row ml-2 mt-2">
          <p className="w-[130px]">Used today:</p>
          <input className="w-48 border border-black h-6  rounded"></input>
        </div>
      </div>

      {/* Report Generation */}
      {LoadingScreenBlock ? (
        <LoadingSpinner />
      ) : (
        <>
          {pdfData ? (
            <div className="w-full justify-center items-center min-h-screen p-4">
              <section className="lg:flex lg:flex-col bg-white p-4 ga  shadow-lg rounded-md w-full min-h-[500px]">
                <div>
                  {/* <div className="flex items-center gap-4"></div> */}

                  <section className="lg:hidden block">
                    {pdfData && (
                      <button
                        className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
                        onClick={handleDownload}
                      >
                        Download PDF
                      </button>
                    )}
                  </section>

                  <section className="hidden lg:block">
                    {pdfData ? (
                      <div className="mt-4">
                        <h2 className="text-lg font-bold">Generated Report</h2>
                        <iframe
                          src={pdfData}
                          title="Generated Report"
                          width="100%"
                          height="600px"
                        ></iframe>
                      </div>
                    ) : (
                      <p className="text-2xl font-bold my-4 text-center">
                        No report available.
                      </p>
                    )}
                  </section>
                </div>
              </section>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );

  return (
    <div>
      <Navbar />
      {isoading ? <Loading /> : renderList}
    </div>
  );
};

export default PettyCash;

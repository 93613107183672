import React, { useState, useContext } from "react";
import waiter from "../Images/waiter.png";
import delivery from "../Images/delivery.png";
import { RiLogoutCircleLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { BsBoxes } from "react-icons/bs";
import { FcBusinessman } from "react-icons/fc";
import { MdReceiptLong } from "react-icons/md";
import { TbReportSearch } from "react-icons/tb";
import { GiRoundTable } from "react-icons/gi";
import { FaWhatsappSquare } from "react-icons/fa";
import { GiCash } from "react-icons/gi";
import { FaFileUpload } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { IoIosListBox } from "react-icons/io";
import { logIn } from "../Redux/Action";
import { AuthContext } from "../context/AuthContext";
import { setDivision } from "../Redux/Action/division";

const HomePageNavbar = () => {
  const navigate = useNavigate();

  const { setAuthUser } = useContext(AuthContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const state = useSelector((state) => state.changeTheState);
  const dispatch = useDispatch();
  function logOut() {
    dispatch(logIn([]));
    dispatch(setDivision(""));
    navigate("/");
    setAuthUser(null);
  }
  return (
    <div>
      <div className="w-full h-auto flex flex-row mb-1 mt-1 overflow-auto">
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/Inventory");
          }}
        >
          <BsBoxes size={50} className="text-yellow-500" />
          <p className="text-xs font-bold">Inventory</p>
        </div>

        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/UploadCategoryImage");
          }}
        >
          <FaFileUpload size={50} className="text-yellow-500" />
          <p className="text-xs font-bold">Upload Image</p>
        </div>

        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/CashPOS");
          }}
        >
          <FcBusinessman size={50} />
          <p className="text-xs font-bold">Cash POS</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/CreditPOS");
          }}
        >
          <FcBusinessman size={50} />
          <p className="text-xs font-bold">Credit POS</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/CreditNoteCash");
          }}
        >
          <FcBusinessman size={50} />
          <p className="text-xs font-bold">Cash Sale Credit Note</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/CreditNoteInvoice");
          }}
        >
          <FcBusinessman size={50} />
          <p className="text-xs font-bold">Credit Note Invoice</p>
        </div>

        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            alert("This Service will be available soon");
          }}
        >
          <img src={delivery} alt="logo" className="w-[50px] " />
          <p className="text-xs font-bold">Delivery</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/Receipt");
          }}
        >
          <MdReceiptLong size={50} />
          <p className="text-xs font-bold">Receipts</p>
        </div>
        <div
          className="relative rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300   w-24 flex flex-col items-center "
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <TbReportSearch size={50} />
          <p className="text-xs font-bold">Reports</p>
          {showDropdown && (
            <div className="w-[250px] fixed  mt-20 ml-35 left-[350px] lg:left-[580px] bg-white border border-gray-300 rounded-md shadow-lg z-50">
              <aside className="flex flex-col items-start">
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                  onClick={() => {
                    navigate("/ZandXReport");
                  }}
                >
                  Z and X Report
                </div>
                <div
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                  onClick={() => {
                    navigate("/SalesReport");
                  }}
                >
                  Sales Report
                </div>
                <div
                  onClick={() => {
                    navigate("/ReceiptReports");
                  }}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left"
                >
                  Receipt
                </div>
                {/* <div className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left">
                  Stock Movement Report
                </div>
                <div className="px-4 py-2 cursor-pointer hover:bg-gray-100 w-[100%] text-left">
                  Pickups Report
                </div> */}
              </aside>
            </div>
          )}
        </div>

        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/TablesSeating");
          }}
        >
          <GiRoundTable size={50} />
          <p className="text-xs font-bold">Tables</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            alert("This Service will be available soon");
          }}
        >
          <img src={waiter} alt="logo" className="w-[50px]" />
          <p className="text-xs font-bold">Waiters</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/PettyCash");
          }}
        >
          <GiCash size={50} className="text-amber-950" />
          <p className="text-xs font-bold">Petty Cash</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={() => {
            navigate("/ListOfOrders");
          }}
        >
          <IoIosListBox size={50} className="text-gray-600" />
          <p className="text-xs font-bold">List Of Orders</p>
        </div>
        <div
          className="rounded-md shadow-xl ml-4 cursor-pointer text-center p-2  py-4  bg-slate-300  hover:bg-slate-400 hover:text-white w-24 flex flex-col items-center"
          onClick={logOut}
        >
          <RiLogoutCircleLine size={50} />
          <p className="text-xs font-bold">Logout</p>
        </div>
      </div>
    </div>
  );
};

export default HomePageNavbar;

import React, { useEffect, useState } from "react";
import HomePageNavbar from "../Components/HomePageNavbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { setDivision } from "../Redux/Action/division";
import Navbar from "../Components/AllPageNavbar";
import DashBoardNavbar from "../Components/DashBoardNavbar";
const HomePage = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const userState = useSelector((state) => state.changeTheState.user);
  const email = userState?.emailId;
  const divisionState = useSelector((state) => state.changeDivision);
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const navigate = useNavigate();
  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [isloading, setisloading] = useState(true);
  const [cashdata, setcashdata] = useState([]);
  const [creditdata, setcreditdata] = useState([]);
  const [creditflag, setcreditflag] = useState(false);
  const [userdivisionRights, setuserDivisionList] = useState([]);
  const [totalVat, settotalVat] = useState(0);
  const [totalLevy, settotalLevy] = useState(0);
  const [totalExcl, settotalExcl] = useState(0);
  const [totalIncl, settotalIncl] = useState(0);
  const [poHeaders, setPoHeaders] = useState([]);

  const dispatch = useDispatch();
  const filtCash = cashdata.filter((item) => {
    const sDate = item.INVDATE;
    const eDate = item.INVDATE;

    if (!startdate && !enddate) {
      return true;
    }

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return dateMatch;
  });

  const filtCredit = creditdata.filter((item) => {
    const sDate = item.INVDATE;
    const eDate = item.INVDATE;

    if (!startdate && !enddate) {
      return true;
    }

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return dateMatch;
  });

  useEffect(() => {
    let totalvat = 0;
    let totallevy = 0;
    let totalexcl = 0;
    let totalincl = 0;

    if (creditflag === false) {
      filtCash.forEach((item) => {
        totalvat += parseFloat(item.VAT) ?? 0;
        totallevy += parseFloat(item.LEVY) ?? 0;
        totalexcl += parseFloat(item.EXCL) ?? 0;
        totalincl += parseFloat(item.INCL) ?? 0;
      });

      settotalVat(totalvat);
      settotalLevy(totallevy);
      settotalExcl(totalexcl);
      settotalIncl(totalincl);
    }
  }, [filtCash, creditflag]);

  useEffect(() => {
    let totalvat = 0;
    let totallevy = 0;
    let totalexcl = 0;
    let totalincl = 0;

    if (creditflag === true) {
      filtCredit.forEach((item) => {
        totalvat += parseFloat(item.VAT) ?? 0;
        totallevy += parseFloat(item.LEVY) ?? 0;
        totalexcl += parseFloat(item.EXCL) ?? 0;
        totalincl += parseFloat(item.INCL) ?? 0;
      });

      settotalVat(totalvat);
      settotalLevy(totallevy);
      settotalExcl(totalexcl);
      settotalIncl(totalincl);
    }
  }, [filtCredit, creditflag]);

  // useEffect(() => {
  //   axios
  //     .post(
  //       `${BASEURL}/getdivisionRights`,
  //       { EMAIL: userState.emailId },
  //       {
  //         headers: {
  //           "auth-token": authToken,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (response.data.length > 0) {
  //         setuserDivisionList(response.data);
  //         if (!divisionState) {
  //           dispatch(setDivision(response.data[0].OM_USER_DIVISION));
  //         }
  //         getSalesBreakdown();
  //       } else {
  //         alert("User rights is not given");
  //         window.history.back();
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  const getSalesBreakdown = () => {
    axios
      .get(`${BASEURL}/SalesBreakdownCash`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcashdata(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Sales Breakdown Cash Sales:", error);
      });

    axios
      .get(`${BASEURL}/SalesBreakdownCredit`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcreditdata(response.data);
        // setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching Sales Breakdown Credit Sales:", error);
        // setisloading(false);
      });
  };

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?OM_USER_ACCOUNT=${email}`)
      .then((response) => {
        console.log(response?.data[0]);
        dispatch(setDivision(response?.data[0]?.OM_USER_DIVISION));
        setuserDivisionList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(getSalesBreakdown, []);
  useEffect(() => {
    getSalesBreakdown();
    getPurchaseOrderDetails();
  }, []);

  function getPurchaseOrderDetails() {
    // setisloading(true);
    axios
      .get(`${BASEURL}/getPoHeaders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setPoHeaders(response?.data);
        setisloading(false);
      })
      .catch((err) => {
        setisloading(false);
        console.log("Error while fetching Purchase Order Details", err);
      });
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <div className=" items-center justify-center">
        <p>
          <DashBoardNavbar />
        </p>
        {/* <p className="text-2xl italic font-bold mb-1">Mars Track POS</p> */}
      </div>

      <HomePageNavbar />
      <section className="lg:flex flex-col ml-2">
        <div className="flex flex-row mb-2 mt-2">
          <p>Sales Breakdown:</p>
          <input
            type="date"
            className="w-[120px] h-6 border-black border ml-2 mr-2 rounded"
            value={startdate.split("T")[0]}
            onChange={(e) => {
              setstartdate(e.target.value);
            }}
          ></input>
          <p>to</p>
          <input
            type="date"
            className="w-[120px] h-6 border-black border ml-2 mr-2 rounded"
            value={enddate.split("T")[0]}
            onChange={(e) => {
              setEnddate(e.target.value);
            }}
          ></input>
          <button
            className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7  text-white"
            onClick={() => {
              navigate("/BuffetOrder");
            }}
          >
            Buffet Order
          </button>
          {/* 
          <button
            className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7 ml-2 text-white"
            onClick={() => {
              navigate("/MyListofOrders");
            }}
          >
            My List Of orders
          </button>
          <button
            className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7 ml-2 text-white"
            onClick={() => {
              navigate("/ListofOrdersNotStarted");
            }}
          >
            Kitchen List Of orders Not Started
          </button>
          <button
            className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7 ml-2 text-white"
            onClick={() => {
              navigate("/ListofOrdersKitchen");
            }}
          >
            Kitchen List Of orders
          </button>
        </div>
        <div className="flex items-center mb-2">
          <button
            className="bg-[#002e62dd] w-[300px] border rounded border-black w-auto px-4 h-7 ml-2 text-white"
            onClick={() => {
              navigate("/ListofOrdersReady");
            }}
          >
            List of Orders Ready
          </button> */}
        </div>
        <div className="flex flex-row mb-1">
          <p>Division:</p>
          <select
            onChange={(e) => {
              dispatch(setDivision(e.target.value));
            }}
            className="w-48 h-6 border-black border ml-2 mr-2 rounded"
          >
            <option>{divisionState}</option>
            {userdivisionRights.map((item) =>
              item.OM_USER_DIVISION !== divisionState ? (
                <option
                  key={item.OM_USER_DIVISION}
                  value={item.OM_USER_DIVISION}
                >
                  {item.OM_USER_DIVISION}
                </option>
              ) : null
            )}
          </select>
          {/* <p className="w-48 h-6 border-black border ml-2 mr-2 rounded">
            {divisionState ? divisionState : " "}
          </p> */}
          {/* <select
            onChange={(e) => {
              dispatch(setDivision(e.target.value));
            }}
            className="w-48 h-6 border-black border ml-2 mr-2 rounded"
          >
           
            {userdivisionRights.map((item) => {
              return (
                <option value={item.OM_USER_DIVISION}>
                  {item.OM_USER_DIVISION}
                </option>
              );
            })}
          </select> */}
          {/* <option>
              {divisionState
                ? divisionState
                : userdivisionRights[0].OM_USER_DIVISION}
            </option> */}
          <p>Type:</p>
          <select
            className="w-48 h-6 border-black border ml-2 mr-2 rounded"
            defaultValue={"CashSale"}
            onChange={(e) => {
              const selectedValue = e.target.value;
              if (selectedValue === "CashSale") {
                setcreditflag(false);
              } else if (selectedValue === "CreditSale") {
                setcreditflag(true);
              }
            }}
          >
            <option value="CashSale">Cash Sale</option>
            <option value="CreditSale">Credit Sale</option>
          </select>

          {/* <button className="rounded-md w-14 h-6 border border-black bg-gray-300 mr-4">
            View
          </button>

          <button
            className="rounded-md w-auto px-2 h-6 border border-black bg-gray-300 mr-4"
            onClick={() => {
              navigate("/TableOrders");
            }}
          >
            Table Orders
          </button>

          <button className="rounded-md w-auto px-2 h-6 border border-black bg-gray-300">
            Online Orders
          </button> */}
        </div>
      </section>
      {/* Table */}

      <div className="h-[47vh] w-full flex overflow-auto">
        <aside className="w-[700px] table__pos__app text-sm overflow-auto">
          <table class="custom-table">
            <thead class="header-cell">
              <tr>
                <td className="text-left">INV DATE</td>
                <td className="text-right">VAT AMOUNT</td>
                <td className="text-right">LEVY AMOUNT</td>
                <td className="text-right">SALES AMOUNT</td>
              </tr>
            </thead>
            <tbody>
              {creditflag === true
                ? filtCredit.map((value, index) => (
                    <tr
                      className={
                        index < creditdata.length
                          ? "border-b cursor-pointer text-sm table-row"
                          : ""
                      }
                    >
                      <td className="text-left">
                        {value.INVDATE
                          ? moment(value.INVDATE).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="text-right">
                        {value.VAT
                          ? value.VAT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.LEVY
                          ? value.LEVY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.INCL
                          ? value.INCL.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  ))
                : filtCash.map((value, index) => (
                    <tr
                      className={
                        index < cashdata.length - 1
                          ? "border-b cursor-pointer text-sm"
                          : ""
                      }
                    >
                      <td className="text-left">
                        {value.INVDATE
                          ? moment(value.INVDATE).format("DD/MM/YYYY")
                          : ""}
                      </td>
                      <td className="text-right">
                        {value.VAT
                          ? value.VAT.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.LEVY
                          ? value.LEVY.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                      <td className="text-right">
                        {value.INCL
                          ? value.INCL.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </aside>

        <aside className="w-[400px] table__pos__app text-sm overflow-auto">
          <table class="custom-table">
            <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
              <tr>
                <td className="text-right ">PO NO</td>
                <td className="text-left">PO DATE</td>
                <td className="text-left">SUPPLIER CODE</td>
                <td className="text-left">SUPPLIER NAME</td>
                <td className="text-left">SUPPLIER CURRENCY</td>
              </tr>
            </thead>
            <tbody>
              {poHeaders?.map((row, index) => (
                <tr
                  className="cursor-pointer"
                  key={row?.PONO}
                  onClick={() => {
                    navigate("/GrnProcessing", {
                      state: { pono: row?.PONO },
                    });
                  }}
                >
                  <td className="text-right pr-6">
                    {row?.PONO ? row?.PONO : ""}
                  </td>
                  <td>
                    {row.PODATE
                      ? moment(row?.PODATE?.split("T")[0]).format("DD/MM/YYYY")
                      : ""}
                  </td>
                  <td>{row?.POSUPPLIERCODE ? row?.POSUPPLIERCODE : ""}</td>
                  <td>
                    {row?.["SUPPLIER NAME"] ? row?.["SUPPLIER NAME"] : ""}
                  </td>
                  <td>{row?.POCURRENCY ? row?.POCURRENCY : ""}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </aside>
      </div>

      <div className="flex flex-row mt-2 ml-2">
        <p className="ml-[90px] mr-2">Total Vat:</p>
        <input
          className="w-48 h-6 border-black border rounded text-right"
          value={
            totalVat
              ? totalVat.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          }
        ></input>
        <p className="ml-20 mr-2">Total Levy:</p>
        <input
          className="w-48 h-6 border-black border rounded text-right"
          value={
            totalLevy
              ? totalLevy.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          }
        ></input>
      </div>
      <div className="flex flex-row mt-2 ml-2">
        <p className="mx-2">Total Sales Exc Taxes:</p>
        <input
          className="w-48 h-6 border-black border rounded text-right"
          value={
            totalExcl
              ? totalExcl.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          }
        ></input>
        <p className="mx-2">Total Sales Inc Taxes:</p>
        <input
          className="w-48 h-6 border-black border rounded text-right"
          value={
            totalIncl
              ? totalIncl.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""
          }
        ></input>
      </div>
    </div>
  );
};

export default HomePage;

import Select from "react-select";
import { React, useEffect, useState, useReducer } from "react";
import OrderTakerNavbarAllPAges from "../../Components/OrderTakerNavbarAllPAges";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { IoFastFoodOutline } from "react-icons/io5";
import Loading from "../../Components/Loading";
import { useNavigate } from "react-router-dom";
import { IoClose } from "react-icons/io5";
import { FaShoppingCart } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import moment from "moment";
import { MdPreview } from "react-icons/md";
import { useLocation } from "react-router-dom";

const TakeOrder = () => {
  const location = useLocation();
  const orderNo = location?.state;
  const [selTable, setSelTable] = useState("");
  const [itemsPopup, setItemsPopUp] = useState(false);
  const BASEURL = process.env.REACT_APP_BASEURL;
  const divisionState = useSelector((state) => state.changeDivision);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [category, setcategory] = useState([]);
  const [itemdetails, setItemdetails] = useState([]);
  const [selcategory, setselcategory] = useState("");
  const [tables, setTables] = useState([]);
  const [isloading, setisloading] = useState(true);
  const [table, setistable] = useState(true);
  const [ShowTable, setShowTable] = useState(false);
  const navigate = useNavigate();
  const userState = useSelector((state) => state.changeTheState.user);
  const loggedInUser = userState?.emailId?.split("@")[0]?.toUpperCase();
  const [clientsList, setClientlist] = useState([]);
  const [paymodeList, setPayModeList] = useState([]);
  const [newOrderNo, setNewOrderNo] = useState("");
  const [selClientName, setSelClientName] = useState("");
  const [selClientCode, setSelClientCode] = useState("");
  const [selClientEmail, setSelclientEmail] = useState("");
  const [selClientPhone, setSelClientPhone] = useState("");
  const [selClientCountry, setSelClientCountry] = useState("");
  const [selClientCity, setSelClientCity] = useState("");
  const [selPaymode, setSelPayMode] = useState("");
  const [vatPerc, setVatPerc] = useState("");
  const [currency, setCurrency] = useState("");
  const [notes, setNotes] = useState("");
  const [date, setDate] = useState(new Date().toISOString());
  const [selClientAddress, setSelClientAddress] = useState("");
  const [vatAmount, setVatAmount] = useState("");
  const [AmountExclVat, setAmountExclVat] = useState("");
  const [AmountInclVat, setAmountInclVat] = useState("");
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString()
  );
  const [itemsList, setItemsList] = useState([]);
  const [viewPopUp, setViewPopUp] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [orderPopUp, setOrderPopUp] = useState(false);
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [search, setSearch] = useState("");
  const [startdate, setstartdate] = useState("");
  const [enddate, setEnddate] = useState("");
  const formattedDate = moment.utc(date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  // Filters for Orders Pop Up
  const filtOrders = orderHeaders.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.ENQUIRYDATE;
    const eDate = item.ENQUIRYDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["CLIENT NAME", "ENQUIRYNO", "MODEOFPAY", "PONOTES"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const handleRowClick = (item) => {
    setItemsList((prevItems) => {
      if (
        prevItems.some(
          (selected) =>
            selected.ITEMCODE === item.ITEMCODE &&
            selected.ITEMDESCRIPTION === item.ITEMDESCRIPTION
        )
      ) {
        // Remove the item if it's already selected
        return prevItems.filter(
          (selected) =>
            selected.ITEMCODE !== item.ITEMCODE ||
            selected.ITEMDESCRIPTION !== item.ITEMDESCRIPTION
        );
      } else {
        // Calculate VAT and POITEMAMOUNT
        const vatAmount = (Number(item.ITEMRATE) * Number(vatPerc)) / 100;
        const amtexclVat = item.ITEMRATE;
        const amtinclVat =
          item.VATABLE === 1
            ? Number(item.ITEMRATE) + vatAmount
            : item.ITEMRATE;
        // Add the item if it's not already selected
        return [
          ...prevItems,
          {
            ...item,
            ITEMQTY: 1,
            PROPERTY: "MILD",
            POITEMAMOUNT: amtinclVat,
            ITEMRATE: amtexclVat,
            VAT: item.VATABLE,
          },
        ];
      }
    });
  };

  const handleQuantityChange = (item, value) => {
    const updatedItems = itemsList.map((selected) => {
      if (
        selected.ITEMCODE === item.ITEMCODE &&
        selected.ITEMDESCRIPTION === item.ITEMDESCRIPTION
      ) {
        const quanity = item.ITEMQTY || 1;
        const itemRate = Number(item.ITEMRATE) / Number(quanity);
        const vatAmount = (Number(itemRate) * Number(vatPerc)) / 100;
        const amtexclVat = itemRate * value;
        const amtinclVat =
          item.VATABLE === 1
            ? (Number(itemRate) + vatAmount) * value
            : itemRate * value;
        return {
          ...selected,
          ITEMQTY: value,
          POITEMAMOUNT: amtinclVat,
          ITEMRATE: amtexclVat,
          VAT: item.VATABLE,
        };
      }
      return selected;
    });
    setItemsList(updatedItems);
  };

  const handleSpiceLevelChange = (item, value) => {
    const updatedItems = itemsList.map((selected) => {
      if (
        selected.ITEMCODE === item.ITEMCODE &&
        selected.ITEMDESCRIPTION === item.ITEMDESCRIPTION
      ) {
        return { ...selected, PROPERTY: value };
      }
      return selected;
    });
    setItemsList(updatedItems);
  };

  // console.log(itemsList);

  useEffect(() => {
    getNewEnqquiryNo();
    getALLTables();
    getAllCategories();
    getOrganizationDetails();
    getListOfClients();
    // getPayModes();
    getOrderHeaders();
  }, []);

  const getALLTables = () => {
    axios
      .post(
        `${BASEURL}/getListOfTables`,
        {
          division: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setTables(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Table details:", error);
      });
  };

  const getAllCategories = () => {
    axios
      .get(`${BASEURL}/getallcategories`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcategory(response.data);
        setisloading(false);
        setistable(false);
      })
      .catch((error) => {
        setisloading(false);
        setistable(false);
        console.error("Error fetching Main Categories:", error);
      });
  };

  const getItemDetails = (value) => {
    axios
      .post(
        `${BASEURL}/getItemDetails`,
        {
          category: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        const transformedData = response.data.map((item) => ({
          ITEMCODE: item.OM_ITEM_CODE,
          ITEMDESCRIPTION: item.OM_ITEM_DESCRIPTION,
          ITEMUOM: item.UOM,
          ITEMRATE: item.SELLINGPRICE,
          ...item, // Include other unchanged properties
        }));
        console.log(transformedData);
        setItemdetails(transformedData);
        setShowTable(true);
        setistable(false);
      })
      .catch((error) => {
        setistable(false);
        console.error("Error fetching List Of Items:", error);
      });
  };

  const getOrganizationDetails = () => {
    axios
      .get(`${BASEURL}/getOrganizationDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVatPerc(response?.data?.[0]?.VATPERC);
        setCurrency(response?.data?.[0]?.BASECURRENCY);
        // setOrganisationDetails(response?.data?.[0]);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getOrganizationDetails:", error);
      });
  };

  const getListOfClients = () => {
    axios
      .get(`${BASEURL}/getListOfClients`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setClientlist(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getListOfClients:", error);
      });
  };

  const getPayModes = () => {
    axios
      .get(`${BASEURL}/getPayModes`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setPayModeList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of /getPayModes:", error);
      });
  };

  const getOrderHeaders = () => {
    axios
      .get(
        `${BASEURL}/getOrderHeaders?DIVISION=${divisionState}&CREATEDBY=${loggedInUser}`,
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setOrderHeaders(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Orders /getOrderHeaders:", error);
      });
  };

  const getOrderItems = (value) => {
    axios
      .get(
        `${BASEURL}/getOrderItems?ENQUIRYNO=${value}`,

        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setItemsList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Items:", error);
      });
  };

  const getNewEnqquiryNo = () => {
    axios
      .get(`${BASEURL}/getNewEnqquiryNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setNewOrderNo(response.data[0].NEXTPONO);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching List Of /getNewEnqquiryNo:", error);
      });
  };

  // *********************** Save *********************
  async function SaveHeader() {
    setisloading(true);
    try {
      const response = await axios.post(
        `${BASEURL}/insertOrderHeaders`,
        {
          SALESENQUIRYNO: Number(newOrderNo),
          SALESENQUIRYITEMSSERVICE: 1,
          CLIENTCODE: selClientCode,
          REFFROM: "",
          SALESENQUIRYDATE: formattedDate,
          SALESENQUIRYVEHICLE: "",
          DIVISION: divisionState ? divisionState : "",
          SALESENQUIRYNOTES: "",
          CREATEDBY: loggedInUser ? loggedInUser : "",
          CREATEDDATE: formattedDate,
          CREATEDTIME: currentTime ? currentTime : "",
          AMOUNTEXCVAT: AmountExclVat ? Number(AmountExclVat) : 0,
          VATAMOUNT: vatAmount ? Number(vatAmount) : 0,
          AMTOUNTINCLUSIVEVAT: AmountInclVat ? Number(AmountInclVat) : 0,
          CURRENCYCODE: currency ? currency : "",
          MODEOFPAY: "CASH",
          CLIENTNAME: selClientName ? selClientName : "",
          DELIVERYADDRESS: selClientAddress ? selClientAddress : "",
          CLIENTEMAIL: selClientEmail ? selClientEmail : "",
          CLIENTCOUNTRY: selClientCountry ? selClientCountry : "",
          CLIENTCITY: selClientCity ? selClientCity : "",
          CLIENTPHONENUMBER: selClientPhone ? selClientPhone : "",
          CARTNO: 0,
          DELIVERYPROVIDED: 1,
          DELIVERYROUTE: 0,
          DELIVERYCHARGES: 0,
          TABLENUMBER: selTable ? selTable : "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 201) {
        alert("Order Header Saved Successfully");
        setisloading(false);
      } else {
        alert(response.data.message);
        setisloading(false);
      }
    } catch (error) {
      setisloading(false);
      console.error(
        `Error while saving Order Headers /insertOrderHeaders`,
        error
      );
      alert(error.response.data.message);
    }
  }

  async function SaveDetails() {
    setisloading(true);
    let successCount = 0;
    let successMessage = null;
    let errorCount = 0;
    let errorMessage = null;
    for (let index = 0; index < itemsList.length; index++) {
      const value = itemsList[index];
      try {
        const response = await axios.post(
          `${BASEURL}/insertOrderItems`,
          {
            SALESENQUIRYNO: Number(newOrderNo),
            SALESENQUIRYDATE: formattedDate,
            SERIALNO: Number(index),
            ITEMCODE: value.ITEMCODE,
            ITEMDESCRIPTION: value.ITEMDESCRIPTION,
            ITEMPROPERTY: value.PROPERTY,
            UOM: value.ITEMUOM,
            ITEMQTY: Number(value.ITEMQTY),
            ITEMRATE: Number(value.ITEMRATE),
            ITEMVAT: value.VAT,
            ITEMCURRENCY: currency,
            ITEMAMOUNT: Number(value.POITEMAMOUNT),
            DIVISION: divisionState ? divisionState : "",
            CREATEDBY: loggedInUser,
            CREATEDDATE: formattedDate,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 201) {
          successCount++;
          successMessage = response.data.message;
        } else {
          errorCount++;
          errorMessage = response.data.message;
        }
      } catch (error) {
        setisloading(false);
        console.error(
          `Error while Saving Details Of Order taking /insertOrderItems `,
          error
        );
        errorCount++;
        errorMessage = error.response.data.message;
        // alert(error.response.data.message);
      }
    }
    setisloading(false);
    if (successCount == itemsList.length && successMessage) {
      alert("Order Details Saved Successfully");
    }
    if (errorMessage) {
      alert(errorMessage);
    }
  }

  // Total calculations
  useEffect(() => {
    let totalExclVat = 0;
    let totalInclVat = 0;

    itemsList.forEach((item) => {
      totalExclVat += parseFloat(item.ITEMRATE);
      totalInclVat += parseFloat(item.POITEMAMOUNT);
    });

    setAmountExclVat(totalExclVat); // Set total excluding VAT
    setAmountInclVat(totalInclVat); // Set total inclusive of VAT
    setVatAmount(totalInclVat - totalExclVat); // Set total VAT amount
  }, [itemsList]);

  // Fetching from Home Page
  // useEffect(() => {
  //   console.log(orderNo);
  //   if (orderNo) {
  //     axios
  //       .get(`${BASEURL}/getOrderHeaders?ENQUIRYNO=${orderNo}`, {
  //         headers: {
  //           "auth-token": authToken,
  //           "session-token": sessiontoken,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response.data);
  //         setOrderHeaders(response?.data);
  //         setSelClientCode(response?.data[0]?.CLIENTCODE);
  //         setSelclientEmail(response?.data[0]?.CLIENTEMAIL);
  //         setSelClientPhone(response?.data[0]?.PHONENUMBER);
  //         setSelClientCountry("");
  //         setSelClientCity("");
  //         setSelClientName(response?.data[0]?.["CLIENT NAME"]);
  //         setSelClientAddress(response?.data[0]?.["CLIENT ADDR1"]);
  //         setNewOrderNo(response?.data[0]?.ENQUIRYNO);
  //         setSelPayMode(response?.data[0]?.MODEOFPAY);
  //         setNotes(response?.data[0]?.PONOTES);
  //         setAmountExclVat(response?.data[0]?.POGROSSAMT);
  //         setAmountInclVat(response?.data[0]?.NETAMT);
  //         setVatAmount(response?.data[0]?.VAT);
  //         setCurrency(response?.data[0]?.POCURRENCY);
  //         getOrderItems(response?.data[0]?.ENQUIRYNO);
  //       })
  //       .catch((error) => {
  //         console.error(
  //           "Error fetching List Of Orders /getOrderHeaders:",
  //           error
  //         );
  //       });
  //   }
  // }, [orderNo]);

  if (isloading) {
    return <Loading />;
  }

  return (
    <>
      <OrderTakerNavbarAllPAges />
      <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page"> TAKE ORDER</h1>
      </header>
      <div className="w-[100%] lg:flex items-center  justify-center">
        <div className="bg-slate-100 m-2 h-auto p-5 lg:w-[91%] rounded mt-4 shadow-lg">
          {/* 1st row */}
          <div className="lg:flex items-center gap-2">
            <p>Order No:</p>
            <input
              className="w-20 rounded-md h-7 text-right pr-2 font-bold"
              value={newOrderNo}
              readOnly
            ></input>
            <button
              className="flex items-center my-1 justify-center border border-black bg-[#002d62] text-white rounded-md px-2  bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setOrderPopUp(true);
              }}
            >
              ...
            </button>
            <p className="lg:ml-2">Table:</p>
            <Select
              className="min-w-[288px] max-w-[288px]"
              onChange={(e) => {
                setSelTable(e.value);
              }}
              value={
                selTable
                  ? { label: selTable, value: selTable }
                  : { label: "Select Table", value: "" }
              }
              options={[
                { label: "Select Table", value: "" }, // Empty option
                ...tables?.map((item) => ({
                  label: item.TABLENUMBER,
                  value: item.TABLENUMBER,
                })),
              ]}
            />
            <p className="ml-2">Customer:</p>
            <Select
              className="w-[300px] text-sm"
              onChange={(e) => {
                if (e.value === "") {
                  setSelClientCode("");
                  setSelclientEmail("");
                  setSelClientPhone("");
                  setSelClientCountry("");
                  setSelClientCity("");
                  setSelClientName("");
                  setSelClientAddress("");
                } else {
                  setSelClientCode("");
                  setSelclientEmail("");
                  setSelClientPhone("");
                  setSelClientCountry("");
                  setSelClientCity("");
                  setSelClientAddress("");
                  setSelClientName(e.value);
                  const selectedOption = clientsList?.find(
                    (obj) => obj["CLIENT NAME"] === e.value
                  );
                  setSelClientCode(selectedOption?.["CLIENT CODE"]);
                  setSelclientEmail(selectedOption?.["EMAIL ADDRESS"]);
                  setSelClientPhone(selectedOption?.["MOBILE NO"]);
                  setSelClientCountry(selectedOption?.COUNTRY);
                  setSelClientCity(selectedOption?.CITY);
                  setSelClientAddress(selectedOption?.["ADDRESS 1"]);
                }
              }}
              value={
                selClientName
                  ? { label: selClientName, value: selClientName }
                  : { label: "Select Client", value: "" }
              }
              options={[
                { label: "Select Client", value: "" }, // Empty option
                ...clientsList?.map((item) => ({
                  label: item["CLIENT NAME"],
                  value: item["CLIENT NAME"],
                })),
              ]}
            />
            <button
              className="bg-[#002e62dd] border rounded mr-2 border-black w-auto px-4 h-7  text-white"
              onClick={() => {
                if (!selTable) {
                  alert("Kindly Supply Table Number");
                } else if (!selClientName) {
                  alert("Kindly Supply Client Details");
                } else {
                  setItemsPopUp(true);
                }
              }}
            >
              Select Items
            </button>
            <button
              className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7  text-white"
              onClick={() => {
                window.location.reload();
              }}
            >
              New Order
            </button>
          </div>

          {/* Order No PopUp */}
          {orderPopUp && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] lg:w-[900px] p-1 overflow-y-auto w-[95%]">
              <div className="lg:flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <p className="text-red-600 ml-2">Start Date:</p>
                <input
                  type="date"
                  className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                  value={startdate.split("T")[0]}
                ></input>
                <p className="text-red-600 ml-2">End Date:</p>
                <input
                  type="date"
                  className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setEnddate(e.target.value);
                  }}
                  value={enddate.split("T")[0]}
                ></input>
              </div>
              <button
                className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => setOrderPopUp(false)}
              >
                Close
              </button>

              <aside className="table__pos__app text-sm ">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td className="pl-2">ORDER NO</td>
                      <td className="text-left">ORDER DATE</td>
                      <td className="text-left">CUSTOMER NAME</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtOrders.map((row, index) => (
                      <tr
                        key={index}
                        onClick={() => {
                          setOrderPopUp(false);
                          setSelClientCode(row.CLIENTCODE);
                          setSelclientEmail(row.CLIENTEMAIL);
                          setSelClientPhone(row.PHONENUMBER);
                          setSelClientCountry("");
                          setSelClientCity("");
                          setSelClientName(row["CLIENT NAME"]);
                          setSelClientAddress(row["CLIENT ADDR1"]);
                          setNewOrderNo(row.ENQUIRYNO);
                          setSelPayMode(row.MODEOFPAY);
                          setNotes(row.PONOTES);
                          setAmountExclVat(row.POGROSSAMT);
                          setAmountInclVat(row.NETAMT);
                          setVatAmount(row.VAT);
                          setCurrency(row.POCURRENCY);
                          setSelTable(row.TABLENUMBER);
                          getOrderItems(row.ENQUIRYNO);
                        }}
                        className={
                          index < orderHeaders.length
                            ? "border-b cursor-pointer text-sm table-row"
                            : ""
                        }
                      >
                        <td className="text-right pr-12">
                          {row.ENQUIRYNO ? row.ENQUIRYNO : ""}
                        </td>
                        <td className="text-left">
                          {row.ENQUIRYDATE
                            ? moment(row.ENQUIRYDATE.split("T")[0]).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </td>
                        <td className="text-left">
                          {row["CLIENT NAME"] ? row["CLIENT NAME"] : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          )}

          {/* Item Details */}
          {itemsPopup && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[78%] w-[95%] p-1 overflow-y-auto rounded-md">
              <div>
                <div className="w-auto flex flex-row">
                  {/* left side */}
                  <div className="w-[620px] flex flex-wrap overflow-y-auto max-h-[500px]">
                    {category.map((item, index) => (
                      <aside
                        key={index}
                        className={`rounded-md border-slate-200 border shadow-xl text-center p-2 ml-2 mt-2 cursor-pointer w-24 flex flex-col items-center ${
                          selcategory === item.MAINCATEGORY
                            ? "bg-blue-200"
                            : "bg-white"
                        }`}
                        onClick={() => {
                          setistable(true);
                          getItemDetails(item.MAINCATEGORY);
                          setselcategory(item.MAINCATEGORY);
                        }}
                      >
                        {item.CATEGORYIMAGE?.data ? (
                          <>
                            {" "}
                            <img
                              className="text-amber-950"
                              src={URL.createObjectURL(
                                new Blob(
                                  [new Uint8Array(item.CATEGORYIMAGE?.data)],
                                  {
                                    type: "image/jpeg",
                                  }
                                )
                              )}
                            />
                          </>
                        ) : (
                          <IoFastFoodOutline
                            size={50}
                            className="text-amber-950"
                          />
                        )}

                        <p className="text-xs">{item.MAINCATEGORY}</p>
                      </aside>
                    ))}
                  </div>

                  {/* right side */}

                  <div className="w-[800px] h-auto pb-2">
                    <div className="flex justify-end">
                      <IoClose size={30} onClick={() => setItemsPopUp(false)} />
                    </div>

                    {table ? (
                      <Loading />
                    ) : (
                      <div className="w-[620px] flex flex-wrap justify-around overflow-y-auto max-h-[500px]">
                        {itemdetails.map((item, index) => (
                          <aside
                            key={item.OM_ITEM_CODE}
                            className={`rounded-md border-slate-200 border shadow-xl text-center p-2 ml-2 mt-2 cursor-pointer w-36 flex flex-col items-center 
                            `}
                          >
                            {item.CATEGORYIMAGE?.data ? (
                              <>
                                {" "}
                                <img
                                  className="text-amber-950"
                                  src={URL.createObjectURL(
                                    new Blob(
                                      [
                                        new Uint8Array(
                                          item.CATEGORYIMAGE?.data
                                        ),
                                      ],
                                      {
                                        type: "image/jpeg",
                                      }
                                    )
                                  )}
                                />
                              </>
                            ) : (
                              <IoFastFoodOutline
                                size={50}
                                className="text-amber-950"
                              />
                            )}

                            <p className="text-xs font-bold">
                              {item.ITEMDESCRIPTION}
                            </p>
                            <div className="flex">
                              <span className="text-xs font-semibold">
                                Item Code :
                              </span>
                              <p className="text-xs">{item.ITEMCODE}</p>
                            </div>
                            <div className="flex">
                              <span className="text-xs font-semibold">
                                Stock Qty :
                              </span>
                              <p className="text-xs">{item.STOCKQTY}</p>
                            </div>
                            <div className="flex">
                              <span className="text-xs font-semibold">
                                Rate :
                              </span>
                              <p className="text-xs">{item.ITEMRATE}</p>
                            </div>
                            {itemsList.some(
                              (value) =>
                                value.ITEMCODE === item.ITEMCODE &&
                                value.ITEMDESCRIPTION === item.ITEMDESCRIPTION
                            ) ? (
                              <div className="border rounded-md lg:flex justify-center items-center py-[2px] mt-2 px-[5px]  mr-[10px] mb-[10px] ml-[10px] cursor-pointer text-white bg-green-900">
                                Added &nbsp; <FaCheck />
                              </div>
                            ) : (
                              <button
                                className="border rounded-md flex justify-center items-center py-[2px] px-[5px]  cursor-pointer text-white bg-gray-900 m-[10px]"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRowClick(item);
                                }}
                              >
                                Add &nbsp; <FaShoppingCart />
                              </button>
                            )}
                          </aside>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* Selected Items */}
          {itemsList.length > 0 && (
            <>
              <aside className="my-4 text-sm overflow-auto">
                <table class="custom-table w-[98%]">
                  <thead class="header-cell">
                    <tr>
                      <td className="text-right">S NO</td>
                      <td className="text-left">ITEM CODE</td>
                      <td className="text-left">ITEM NAME</td>
                      <td className="text-right">QUANTITY</td>
                      <td className="text-left">SPICE LEVEL</td>
                      <td className="text-center">ACTION</td>
                      <td className="text-center">VIEW</td>
                    </tr>
                  </thead>
                  <tbody>
                    {itemsList?.map((item, index) => {
                      return (
                        <tr className="table-row cursor-pointer" key={index}>
                          <td className="text-right">{index + 1}</td>
                          <td className="text-left">{item.ITEMCODE}</td>
                          <td
                            className="text-left"
                            style={{ maxWidth: "200px" }}
                          >
                            {item.ITEMDESCRIPTION}
                          </td>
                          <td className="text-right pr-2">
                            {
                              <input
                                type="number"
                                className="w-20 h-7 rounded-md text-right pr-2"
                                defaultValue={item.ITEMQTY ? item.ITEMQTY : ""}
                                onChange={(e) => {
                                  handleQuantityChange(item, e.target.value);
                                }}
                              ></input>
                            }
                          </td>
                          <td>
                            {
                              <Select
                                className="w-48"
                                value={
                                  item.PROPERTY
                                    ? {
                                        value: item.PROPERTY,
                                        label: item.PROPERTY,
                                      }
                                    : {
                                        value: "",
                                        label: "Select Spice Level",
                                      }
                                }
                                onChange={(selectedOption) =>
                                  handleSpiceLevelChange(
                                    item,
                                    selectedOption.value
                                  )
                                }
                                options={[
                                  {
                                    value: "",
                                    label: "Select Spice Level",
                                  },
                                  {
                                    label: "MILD",
                                    value: "MILD",
                                  },
                                  {
                                    label: "MEDIUM SPICY",
                                    value: "MEDIUM SPICY",
                                  },
                                  {
                                    label: "HOT",
                                    value: "HOT",
                                  },
                                  {
                                    label: "VERY HOT",
                                    value: "VERY HOT",
                                  },
                                ]}
                                menuPortalTarget={document.body} // Render the dropdown menu in the body
                                styles={{
                                  menuPortal: (base) => ({
                                    ...base,
                                    zIndex: 9999,
                                  }),
                                }} // Set a high z-index for the dropdown menu
                              ></Select>
                            }
                          </td>
                          <td>
                            <div className="lg:flex justify-center">
                              <MdDeleteForever
                                size={25}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRowClick(item);
                                }}
                              />
                            </div>
                          </td>
                          <td>
                            <div className="lg:flex justify-center">
                              <MdPreview
                                size={25}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedItem(item);
                                  setViewPopUp(true);
                                }}
                              />
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </aside>

              <div className="w-full lg:flex items-center  justify-center">
                <button
                  className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-6  text-white"
                  onClick={async () => {
                    for (const item of itemsList) {
                      const quantity = item.ITEMQTY;
                      const spiceLevel = item.PROPERTY;

                      // Validate quantity for each selected item
                      if (!quantity || quantity <= 0) {
                        alert(
                          `Please ensure the quantity is provided for the item: ${item.OM_ITEM_DESCRIPTION}`
                        );
                        return;
                      }

                      // Validate spice level for each selected item
                      if (!spiceLevel || spiceLevel === "") {
                        alert(
                          `Please ensure a spice level is selected for the item: ${item.OM_ITEM_DESCRIPTION}`
                        );
                        return;
                      }
                    }
                    await SaveHeader();
                    await SaveDetails();
                    window.location.reload();
                  }}
                >
                  Place Order
                </button>
              </div>
            </>
          )}

          {/* DETAILED VIEW POPUP */}
          {viewPopUp && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[65%] w-[75%] p-1 overflow-y-auto rounded-md">
              <div className="flex justify-end">
                <IoClose
                  size={30}
                  onClick={() => {
                    setSelectedItem({});
                    setViewPopUp(false);
                  }}
                />
              </div>

              <div>
                {selectedItem && (
                  <div className="p-4 bg-gray-100 rounded-md">
                    <h2 className="text-2xl font-extrabold text-center mb-4">
                      Selected Item Details
                    </h2>
                    <div className="flex justify-center items-center">
                      {selectedItem.IMAGENAME?.data ? (
                        <>
                          {" "}
                          <img
                            className="text-amber-950"
                            src={URL.createObjectURL(
                              new Blob(
                                [
                                  new Uint8Array(
                                    selectedItem.CATEGORYIMAGE?.data
                                  ),
                                ],
                                {
                                  type: "image/jpeg",
                                }
                              )
                            )}
                          />
                        </>
                      ) : (
                        <IoFastFoodOutline
                          size={50}
                          className="text-amber-950"
                        />
                      )}
                    </div>
                    {/*  */}
                    <div className="grid grid-cols-3 gap-4 mt-2">
                      <div>
                        <strong>ITEM CODE:</strong>{" "}
                        {selectedItem.ITEMCODE || ""}
                      </div>
                      <div>
                        <strong>ITEM NAME:</strong>{" "}
                        {selectedItem.ITEMDESCRIPTION || ""}
                      </div>
                      <div>
                        <strong>SPICE LEVEL:</strong>{" "}
                        {selectedItem.PROPERTY || ""}
                      </div>
                      <div>
                        <strong>UOM:</strong> {selectedItem.ITEMUOM || ""}
                      </div>
                      <div>
                        <strong>QTY ADDED:</strong> {selectedItem.ITEMQTY || ""}
                      </div>
                      <div>
                        <strong>ITEM RATE:</strong>{" "}
                        {selectedItem.ITEMRATE?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || ""}
                      </div>
                      <div>
                        <strong>AMOUNT EXCL VAT:</strong>{" "}
                        {selectedItem.ITEMRATE?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || ""}
                      </div>
                      <div>
                        <strong>AMOUNT INCL VAT:</strong>{" "}
                        {selectedItem.POITEMAMOUNT?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) || ""}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TakeOrder;

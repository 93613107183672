import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import OrderTakerNavbar from "../../Components/OrderTakerNavbar";
import Loading from "../../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { setDivision } from "../../Redux/Action/division";
import { useNavigate } from "react-router-dom";
import waiter from "../../Images/waiter.svg";
import list from "../../Images/list.svg";
import sales from "../../Images/sales.svg";
import { Link } from "react-router-dom";

const ORDERTAKERDASHBOARD = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const userState = useSelector((state) => state?.changeTheState?.user);
  const loggedInUser = userState?.emailId;
  const [orderHeaders, setOrderHeaders] = useState([]);
  const [userdivisionRights, setuserDivisionList] = useState([]);
  const divisionState = useSelector((state) => state.changeDivision);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?OM_USER_ACCOUNT=${loggedInUser}`)
      .then((response) => {
        dispatch(setDivision(response?.data[0]?.OM_USER_DIVISION));
        setuserDivisionList(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(
          "Error while fetching Division Rights : /getdivisionRights",
          err
        );
        setIsLoading(false);
      });
    // getOrderHeaders();
  }, []);

  function getOrderHeaders() {
    axios
      .get(`${BASEURL}/getOrderHeaders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((res) => {
        setOrderHeaders(res?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("Error while fetching /getOrders List", err);
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="bg-slate-100 min-h-[100vh]">
      <OrderTakerNavbar />
      {/* <header
        className="header__of__main"
        style={{ textAlign: "center", margin: "15px 0px" }}
      >
        <h1 className="header__of__page"> Welcome to MarsTrack POS </h1>
      </header> */}
      <section>
        <div className="lg:flex justify-center lg:flex-row m-3 font-bold">
          <p>Division:</p>
          <select
            onChange={(e) => {
              dispatch(setDivision(e.target.value));
            }}
            className="lg:w-48 w-full h-6 border-black border lg:ml-2 lg:mr-2 rounded"
          >
            <option>{divisionState}</option>
            {userdivisionRights.map((item) =>
              item.OM_USER_DIVISION !== divisionState ? (
                <option
                  key={item.OM_USER_DIVISION}
                  value={item.OM_USER_DIVISION}
                >
                  {item.OM_USER_DIVISION}
                </option>
              ) : null
            )}
          </select>
        </div>
      </section>
      <section className="flex items-center justify-center gap-10 my-10 mx-2 flex-wrap ">
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/MyListofOrders">
            {" "}
            <img
              className="w-[250px] h-[150px] object-fit rounded"
              src={list}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                My List of Orders
              </h2>
            </div>
          </Link>
        </div>
        <div className="bg-white rounded-lg overflow-hidden shadow-lg cursor-pointer w-[250px]">
          <Link to="/TakeOrder">
            {" "}
            <img
              className="w-[250px] h-[150px] object-fit rounded"
              src={waiter}
              alt="serviced Image"
            />
            <div className="p-6">
              <h2 className="text-xl  text-gray-800 text-center font-bold">
                {" "}
                Take Order{" "}
              </h2>
            </div>
          </Link>
        </div>
      </section>

      {/* List Of My Orders */}
      {/* <div className="h-[47vh] w-full lg:flex overflow-auto">
        <aside className="lg:w-[400px] table__pos__app text-sm overflow-auto">
          <h1 className="font-bold underline">My List Of Orders</h1>
          <table class="custom-table">
            <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
              <tr>
                <td className="pl-2">ENQUIRY NO</td>
                <td className="text-left">ENQUIRY DATE</td>
                <td className="text-left">CUSTOMER NAME</td>
                <td className="text-left">MODE OF PAY</td>
                <td className="text-left">NOTES</td>
                <td className="text-left">ACTION</td>
              </tr>
            </thead>
            <tbody>
              {orderHeaders.map((row, index) => (
                <tr
                  key={index}
                  className={
                    index < orderHeaders.length
                      ? "border-b cursor-pointer text-sm table-row"
                      : ""
                  }
                >
                  <td className="text-right pr-12">
                    {row.ENQUIRYNO ? row.ENQUIRYNO : ""}
                  </td>
                  <td className="text-left">
                    {row.ENQUIRYDATE
                      ? moment(row.ENQUIRYDATE.split("T")[0]).format(
                          "DD/MM/YYYY"
                        )
                      : ""}
                  </td>
                  <td className="text-left">
                    {row["CLIENT NAME"] ? row["CLIENT NAME"] : ""}
                  </td>
                  <td className="text-left">
                    {row.MODEOFPAY ? row.MODEOFPAY : ""}
                  </td>
                  <td className="">{row.PONOTES ? row.PONOTES : ""}</td>
                  <td>
                    <button
                      className="bg-[#002e62dd] border rounded border-black w-auto px-4 h-7  text-white"
                      onClick={() => {
                        navigate("/TakeOrder", { state: row.ENQUIRYNO });
                      }}
                    >
                      PayBill
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </aside>
      </div> */}
    </div>
  );
};

export default ORDERTAKERDASHBOARD;

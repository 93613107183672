import React, { useEffect, useState } from "react";
import "../CSS/ItemMaster.css";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
// import AllPagesNavbar from "../Components/AllPagesNavbar";
import AllPageNavbar from "../Components/AllPageNavbar";
import Loading from "../Components/Loading";
import { useDispatch, useSelector } from "react-redux";
import { BsCheckLg } from "react-icons/bs";

const ItemCreation = () => {
  const userState = useSelector((state) => state.changeTheState.user);
  const state = useSelector((state) => state?.loginReducer?.data);
  const divisionState = useSelector((state) => state.changeDivision);
  const userName = userState?.emailId?.split("@")[0]?.toUpperCase();
  const [date, setDate] = useState(new Date().toISOString());
  const BASEURL = process.env.REACT_APP_BASEURL;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const [item, setItem] = useState([]);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [classification, setClassification] = useState([]);
  const [itemcode, setItemcode] = useState("");
  const [itemname, setItemname] = useState("");
  const [selcategory, setselcategory] = useState("");
  const [selsubcategory, setselsubcategory] = useState("");
  const [selclassification, setselclassification] = useState("");
  const [description, setdescription] = useState("");
  const [fetchrow, setfetchrow] = useState(false);
  const [isloading, setisloading] = useState(true);
  const [partno, setPartNo] = useState("");
  const [baseuom, setBaseUom] = useState("");
  const [alternateuom, setAlternateUom] = useState("");
  const [conversionfactor, setConversionFactor] = useState("");
  const [vatable, setVatable] = useState(false);
  const [freeze, setFreeze] = useState(false);
  const [search, setSearch] = useState("");
  const [uom, setUom] = useState([]);
  const [sellingPrice, setSellingPrice] = useState("");

  const filtProducts = item.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return [
        "ITEMCODE",
        "ITEM NAME",
        "CATEGORY",
        "SUBCATEGORY",
        "ITEM CLASSIFICATION",
        "UOM",
      ].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  useEffect(() => {
    getCatgeory();
    getClassification();
    getUOM();
    getItemDetails();
  }, []);

  const getItemDetails = () => {
    axios
      .get(`${BASEURL}/getListOfInventory?DIVISION=${divisionState}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setisloading(false);
        setItem(response.data);
      })
      .catch((error) => {
        setisloading(false);
        console.error("Error fetching Item details:", error);
      });
  };

  const getUOM = () => {
    axios
      .get(`${BASEURL}/getUOM`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setUom(response.data);
      })
      .catch((error) => {
        console.error("Error fetching UOM Details:", error);
      });
  };

  const getCatgeory = () => {
    axios
      .get(`${BASEURL}/getItemCategory`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setCategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Category:", error);
      });
  };

  const getSubCatgeory = (value) => {
    axios
      .post(
        `${BASEURL}/getItemSubCategory`,
        {
          MAINCATEGORY: value,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setSubcategory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Sub Category:", error);
      });
  };

  const getClassification = () => {
    axios
      .get(`${BASEURL}/getItemClassification`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setClassification(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Classification:", error);
      });
  };
  const handleRowClick = (value, index) => {
    setfetchrow(true);
    setItemcode(value.ITEMCODE);
    setItemname(value["ITEM NAME"]);
    setPartNo(value.PARTNO);
    setdescription(value.ITEMDESCRIPTION);
    setselcategory(value.CATEGORY);
    setselsubcategory(value.SUBCATEGORY);
    setselclassification(value["ITEM CLASSIFICATION"]);
    setBaseUom(value.UOM);
    setConversionFactor(value["ITEM CONV FACTOR"]);
    setVatable(value["ITEM VATABLE"] == 1 ? true : false);
    setAlternateUom(value["ITEM ALT UOM"]);
    setFreeze(value.FREEZE == 1 ? true : false);
    setSellingPrice(value.SELLINGPRICE);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Saving
  async function SaveItemMaster(typeSubmit) {
    if (typeSubmit === "SAVE") {
      try {
        const response = await axios.post(
          `${BASEURL}/insertInventory/new`,
          {
            ITEMCODE: itemcode,
            ITEMGENERICNAME: itemname,
            ITEMDESCRIPTION: description,
            PARTNO: partno,
            CLASSIFICATION: selclassification,
            CATEGORY: selcategory,
            SUBCATEGORY: selsubcategory,
            BASEUOM: baseuom,
            ALTUOM: alternateuom,
            CONVFACTOR: Number(conversionfactor),
            VATABLE: vatable == false ? 0 : 1,
            SELLINGPRICE: Number(sellingPrice),
            FREEZE: freeze == false ? 0 : 1,
            CREATEDBY: userName,
            CREATEDDATE: date,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );
        // alert(response.data.message);
        if (response.status === 201) {
          alert(`Item Master saved successfully`);
        } else {
          alert(`Error while saving Item Master`);
        }
      } catch (error) {
        alert(error.response.data.message);
        console.error(`Error while saving Item Master`, error);
      }
    }
    if (typeSubmit === "UPDATE") {
      try {
        const response = await axios.post(
          `${BASEURL}/insertInventory/update`,
          {
            ITEMCODE: itemcode,
            ITEMGENERICNAME: itemname,
            ITEMDESCRIPTION: description,
            PARTNO: partno,
            CLASSIFICATION: selclassification,
            CATEGORY: selcategory,
            SUBCATEGORY: selsubcategory,
            BASEUOM: baseuom,
            ALTUOM: alternateuom,
            CONVFACTOR: Number(conversionfactor),
            SELLINGPRICE: Number(sellingPrice),
            VATABLE: vatable == false ? 0 : 1,
            FREEZE: freeze == false ? 0 : 1,
            CREATEDBY: userName,
            CREATEDDATE: date,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        // alert(response.data.message);
        if (response.status === 201) {
          alert(`Item Master Updated successfully`);
        } else {
          alert(`Error while Updating Item Master`);
        }
      } catch (error) {
        alert(error.response.data.message);
        console.error(`Error while saving Item Master`, error);
      }
    }
  }

  if (isloading) {
    return <Loading />;
  }

  return (
    <div>
      <AllPageNavbar />

      <header className="header__of__main lg:flex justify-between items-end px-3">
        <h1 className="header__of__page"> Item Master </h1>
        <p>
          {" "}
          <p className="text-red-800 text-2xl">STOCK LIST</p>
        </p>
        <div className="flex">
          <button
            className="Save_item"
            onClick={() => {
              if (itemcode || itemname) {
                if (window.confirm("Are you sure you don't want to Save")) {
                  window.location.reload();
                }
              } else {
                window.location.reload();
              }
            }}
          >
            New
          </button>

          <button
            className="Save_item"
            onClick={async () => {
              if (!itemcode) {
                alert("Kindly Supply Item Code");
              } else if (!itemname) {
                alert("Kindly Supply Item Name");
              } else if (!selcategory) {
                alert("Kindly Supply Category");
              } else if (!selsubcategory) {
                alert("Kindly Supply Sub Category");
              } else if (!selclassification) {
                alert("Kindly Supply Classification");
              } else if (!partno) {
                alert("Kindly Supply Part Number");
              } else if (!sellingPrice) {
                alert("Kindly Supply Selling Price");
              } else if (!baseuom) {
                alert("Kindly Supply Base UOM");
              } else if (!alternateuom) {
                alert("Kindly Supply Alternate UOM");
              } else if (!conversionfactor) {
                alert("Kindly Supply Conversion Factor");
              } else {
                await SaveItemMaster("SAVE");
                window.location.reload();
              }
            }}
          >
            Save
          </button>
          <button
            className="Save_item"
            onClick={async () => {
              if (!itemcode) {
                alert("Kindly Supply Item Code");
              } else if (!itemname) {
                alert("Kindly Supply Item Name");
              } else if (!selcategory) {
                alert("Kindly Supply Category");
              } else if (!selsubcategory) {
                alert("Kindly Supply Sub Category");
              } else if (!selclassification) {
                alert("Kindly Supply Classification");
              } else if (!partno) {
                alert("Kindly Supply Part Number");
              } else if (!baseuom) {
                alert("Kindly Supply Base UOM");
              } else if (!alternateuom) {
                alert("Kindly Supply Alternate UOM");
              } else if (!conversionfactor) {
                alert("Kindly Supply Conversion Factor");
              } else {
                await SaveItemMaster("UPDATE");
                window.location.reload();
              }
            }}
          >
            Update
          </button>
        </div>
      </header>

      <section className="section__Item_Master">
        <div className="section__div_item">
          <div className="item_second_line">
            <div className="item_code">
              <p>Item Code</p>
              <input
                type="text"
                className="select_code_item"
                value={itemcode}
                onChange={(e) => {
                  setItemcode(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
            <div className="item_name">
              <p>Item Name</p>

              <input
                type="text"
                className="select_name_item"
                value={itemname}
                onChange={(e) => {
                  setItemname(e.target.value);
                }}
                disabled={fetchrow == true}
              />
            </div>
            <div className="category">
              <p>Category</p>

              <div className="select__margin__master">
                <Select
                  className="pro_select_1"
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Category");
                    } else {
                      setselcategory(selectedOption.value);
                      getSubCatgeory(selectedOption.value);
                    }
                  }}
                  value={
                    selcategory
                      ? {
                          label: selcategory,
                          value: selcategory,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...category.map((item) => ({
                      value: item.CATEGORY,
                      label: item.CATEGORY,
                    })),
                  ]}
                />
              </div>
            </div>
          </div>
          <div className="item_third_line_item">
            <div className="sub-category">
              <p>Sub Category</p>
              <div className="lg:w-[200px] w-[100%]">
                <Select
                  onChange={(selectedOption) => {
                    if (selectedOption.value === "") {
                      alert("Kinly select Proper Sub category");
                    } else {
                      setselsubcategory(selectedOption.value);
                    }
                  }}
                  value={
                    selsubcategory
                      ? {
                          label: selsubcategory,
                          value: selsubcategory,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    { value: "", label: "" },
                    ...subcategory.map((item) => ({
                      value: item.SUBCATEGORY,
                      label: item.SUBCATEGORY,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="classification_item">
              <p>Classification</p>

              <Select
                className="pro_select_1"
                onChange={(selectedOption) => {
                  if (selectedOption.value === "") {
                    alert("Kinly select Proper Classification");
                  } else {
                    setselclassification(selectedOption.value);
                  }
                }}
                value={
                  selclassification
                    ? {
                        label: selclassification,
                        value: selclassification,
                      }
                    : { label: "", value: "" }
                }
                options={[
                  { value: "", label: "" },
                  ...classification.map((item) => ({
                    value: item.OM_ITEM_CLASSIFICATION_CODE,
                    label: item.OM_ITEM_CLASSIFICATION_CODE,
                  })),
                ]}
              />
            </div>
            <div className="standard_part">
              <p>Part No</p>
              <input
                type="text"
                className="item__code__input1"
                value={partno}
                onChange={(e) => {
                  setPartNo(e.target.value);
                }}
              />
            </div>
            <div className="standard_part ml-2">
              <p>Selling Price</p>
              <input
                type="number"
                className="w-32 h-8"
                value={sellingPrice}
                onChange={(e) => {
                  setSellingPrice(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="item_fourth_line_item"></div>
          <div className="item_second_line">
            <div className="item_code">
              <p>Base UOM</p>
              <div className="lg:w-[200px] w-[100%]">
                <Select
                  onChange={(selectedOption) => {
                    setBaseUom(selectedOption.value);
                    setAlternateUom(selectedOption.value);
                  }}
                  value={
                    baseuom
                      ? {
                          label: baseuom,
                          value: baseuom,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    ...uom.map((item) => ({
                      value: item.OM_UOM_CODE,
                      label: item.OM_UOM_CODE,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="standard_cost">
              <p>Alternate UOM</p>
              <div className="lg:w-[200px] w-[100%]">
                <Select
                  onChange={(selectedOption) => {
                    setAlternateUom(selectedOption.value);
                  }}
                  value={
                    alternateuom
                      ? {
                          label: alternateuom,
                          value: alternateuom,
                        }
                      : { label: "", value: "" }
                  }
                  options={[
                    ...uom.map((item) => ({
                      value: item.OM_UOM_CODE,
                      label: item.OM_UOM_CODE,
                    })),
                  ]}
                />
              </div>
            </div>
            <div className="conversion_factor">
              <p>Conversion Factor</p>
              <input
                type="text"
                className="select_code_item"
                value={conversionfactor}
                onChange={(e) => {
                  setConversionFactor(e.target.value);
                }}
              />
            </div>
            <div className="input_boxes">
              <p>Vatable</p>
              <input
                type="checkbox"
                checked={vatable == true}
                onClick={() => {
                  setVatable(!vatable);
                }}
              />
              <p>Freeze</p>
              <input
                type="checkbox"
                checked={freeze == true}
                onClick={() => {
                  setFreeze(!freeze);
                }}
              />
            </div>
          </div>
          <div className="item_second_line"></div>
          <div className="description_item">
            <p className="lg:mb-[50px]">Item Description</p>
            <textarea
              name=""
              id=""
              className="text__area__item_item"
              value={description}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            ></textarea>
          </div>
        </div>
        <div className="table__pos__app w-[98%]  ">
          <div className="lg:flex">
            <p className="lg:mx-2 text-lg">Search</p>
            <input
              className="w-[200px] lg:mr-[50px]"
              required
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>
          <table class="custom-table ">
            <thead class="header-cell">
              <tr>
                <td>ITEM CODE</td>
                <td>ITEM NAME</td>
                <td>CATEGORY</td>
                <td>SUB CATEGORY</td>
                <td>CLASSIFICATION</td>
                <td>UOM</td>
              </tr>
            </thead>
            <tbody>
              {filtProducts.map((value, index) => (
                <tr
                  key={index}
                  className="table-row"
                  onClick={() => {
                    handleRowClick(value, index);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ textAlign: "right", paddingRight: "30px" }}>
                    {value.ITEMCODE || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value["ITEM NAME"] || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.CATEGORY || "N/A"}
                  </td>
                  <td style={{ paddingLeft: "20px" }}>
                    {value.SUBCATEGORY || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value["ITEM CLASSIFICATION"] || "N/A"}
                  </td>
                  <td style={{ textAlign: "left", paddingRight: "50px" }}>
                    {value.UOM || "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ItemCreation;

import { React, useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import axios from "axios";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../Components/Loading";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { setDivision } from "../Redux/Action/division";
import LoadingSpinner from "../Components/Loader/UploadLoader";

const CreditNoteCash = () => {
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_REPORTS = process.env.REACT_APP_BASEURL_REPORTS;
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.changeTheState.user);
  const email = userState?.emailId;
  const divisionState = useSelector((state) => state.changeDivision);
  const [division, setdivsion] = useState(divisionState);
  const [userid, setuserid] = useState(
    userState.emailId.split("@")[0].toUpperCase()
  );
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const navigate = useNavigate();
  const [date, setDate] = useState(new Date().toISOString());
  const [newCashPOSNo, setnewCashPOSNo] = useState(null);
  const [seatingFlag, setSeatingFlag] = useState(null);
  const [listoftables, setlistoftables] = useState([]);
  const [seltable, setseltable] = useState("");
  const [listofservers, setlistofservers] = useState([]);
  const [selservername, setselservername] = useState("");
  const [listofProducts, setListOfProducts] = useState([]);
  const [selProductname, setselProductname] = useState("");
  const [Rate, setRate] = useState("");
  const [rateexc, setrateexc] = useState("");
  const [Quantity, setQuantity] = useState("");
  const [actualQuantity, setActualQuantity] = useState("");
  const [levyflag, setlevyflag] = useState(false);
  const [levyamt, setlevyamt] = useState(null);
  const [levyperc, setlevyperc] = useState(null);
  const [vatflag, setvatflag] = useState(false);
  const [vatamt, setvatamt] = useState(null);
  const [hvatamt, sethvatamt] = useState("");
  const [hlevyamt, sethlevyamt] = useState("");
  const [hlevyperc, sethlevyperc] = useState("");
  const [hgrandtotal, sethgrandtotal] = useState("");
  const [historic, sethistoric] = useState([]);
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [listofCashSales, setlistofCashSales] = useState([]);
  const [listofCashSales1, setlistofCashSales1] = useState([]);
  const [CashSaleHistoric, setCashSaleHistoric] = useState(false);
  const [vatperc, setvatperc] = useState(null);
  const [ShowTable, setShowTable] = useState(false);
  const [productcode, setProductCode] = useState("");
  const [listofitemstaken, setlistofitemstaken] = useState([]);
  let totalquantity = null;
  let totalhquantity = null;
  let grandtotal = null;
  let totalvat = null;
  let totallevy = null;
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [isloading, setisloading] = useState(true);
  const [mobileno, setmobileno] = useState(null);
  const [amountpaid, setamountpaid] = useState(null);
  const [ListOfAssociatedProducts, setListOfAssociatedProducts] = useState([]);
  const [associated, setassociated] = useState("");
  const [showassociatedtable, setshowassociatedtable] = useState(false);
  const [showitems, setshowitems] = useState(false);
  const [showassociated, setshowassociated] = useState(false);
  const [tillno, settillno] = useState("");
  const [search, setSearch] = useState("");
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );

  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [searchprod, setSearchprod] = useState("");
  const [vatregno, setvatregno] = useState("");
  const [pinno, setpinno] = useState("");
  const [orgname, setorgname] = useState("");
  const [adress1, setadress1] = useState("");
  const [adress2, setadress2] = useState("");
  const [adress3, setadress3] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [compCode, setcompCode] = useState("");
  const [firstcashposno, setfirstcashposno] = useState("");
  const [historicAssociated, setHistoricAssociated] = useState([]);

  const [pdfData, setPdfData] = useState(null);
  const [LoadingScreenBlock, setLoadingScreenBlock] = useState(false);
  const [hist, setHist] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportPath, setReportPath] = useState("");

  const [userdivisionRights, setuserDivisionList] = useState([]);

  useEffect(() => {
    axios
      .get(`${BASEURL}/getdivisionRights?OM_USER_ACCOUNT=${email}`)
      .then((response) => {
        setuserDivisionList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  listofitemstaken.forEach((item) => {
    totalquantity += parseFloat(item.quanity);
    grandtotal += parseFloat(item.totalamount);
    totalvat += parseFloat(item.vatamt);
    totallevy += parseFloat(item.levyamt);
  });

  historic.forEach((item) => {
    totalhquantity += parseFloat(item.QTY);
  });

  const handleOptionSelect = (option) => {
    setSeatingFlag(option);
  };

  const filtHistoricCash = listofCashSales1.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.INVDATE;
    const eDate = item.INVDATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["INVNO", "SALES LOC", "CUSTOMER NAME", "FINANCIAL YEAR"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  const filtProducts = listofProducts.filter((item) => {
    const searchData = searchprod.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["OM_ITEM_CODE", "OM_ITEM_DESCRIPTION", "STOCKQTY", "UOM"].some(
        (key) => {
          const columnValue = String(item[key]).toLowerCase();
          return columnValue.includes(term);
        }
      );
    });

    return textMatch;
  });

  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,

      marginTop: "0px",
      minHeight: "initial",
      height: "31px",
      border: "1px solid",
      paddingBottom: "0px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  useEffect(() => {
    getDetails();
    getCompanyDetails();
    getReportName();
    const updateCurrentTime = () => {
      const currentDate = new Date();
      const formattedTime = currentDate.toLocaleTimeString();
      setCurrentTime(formattedTime);
    };

    updateCurrentTime();
    const intervalId = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  function getReportName() {
    axios
      .get(`${BASEURL_REPORTS}/getreportnames?DOCUMENTNAME=Cash Sale`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setReportName(response?.data[0]?.CRNAME);
        setReportPath(response?.data[0]?.REPORTPATH);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }

  const generateInvoicePDF = () => {
    const doc = new jsPDF();
    let yPos = 10;
    doc.setFontSize(20);
    doc.text(orgname ? orgname : "", 105, yPos, { align: "center" });
    yPos += 10;
    doc.setFontSize(15);
    doc.text("Tel : 0710237671", 105, yPos, { align: "center" });
    yPos += 10;
    doc.setFontSize(15);
    doc.text(adress1 ? adress1 : "", 40, yPos);
    doc.text(",", 64, yPos);
    doc.text(adress2 ? adress2 : "", 65, yPos);
    doc.text(",", 89, yPos);
    doc.text(adress3 ? adress3 : "", 90, yPos);
    doc.text(",", 104, yPos);
    doc.text(city ? city : "", 105, yPos);
    doc.text(",", 129, yPos);
    doc.text(country ? country : "", 130, yPos);
    yPos += 10;
    doc.setFontSize(13);
    doc.text("BRANCH : ", 20, yPos);
    doc.text("TWO RIVERS", 50, yPos);
    doc.text("VAT REG NO : ", 100, yPos);
    doc.text(vatregno ? vatregno.toString() : "", 140, yPos);
    yPos += 7;
    doc.text("PIN NO : ", 20, yPos);
    doc.text(pinno ? pinno.toString() : "", 50, yPos);
    doc.text("TILL NO : ", 100, yPos);
    doc.text(tillno ? tillno.toString() : "", 140, yPos);
    yPos += 7;
    doc.text("BILL NO : ", 20, yPos);
    doc.text(newCashPOSNo.toString(), 50, yPos);
    doc.text("BILL DATE : ", 100, yPos);
    doc.text(
      date ? moment(date.split("T")[0]).format("DD/MM/YYYY") : "",
      140,
      yPos
    );
    // Draw a horizontal line after the header
    yPos += 10;
    doc.setFontSize(20);
    doc.text("BILL", 90, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.line(20, yPos, 190, yPos);

    yPos += 10;
    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);

    // Headers for service name and amount
    doc.text("ITEM NAME", 20, yPos);
    doc.text("QUANTITY", 100, yPos);
    doc.text("PRICE", 135, yPos);
    doc.text("AMOUNT", 160, yPos);

    yPos += 5;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 5;

    let totalAmount = 0;

    historic.length > 0
      ? historic.forEach((item, index) => {
          yPos += 5;
          // Adjusted widths for columns
          const productNameWidth = 80;
          // Wrap the ProductName within the allocated space
          const wrappedProductName = doc.splitTextToSize(
            item["ITEM NAME"],
            productNameWidth
          );
          // Draw each column
          doc.text(wrappedProductName, 20, yPos);
          doc.text(item.QTY.toString(), 115, yPos);
          doc.text(item["ITEM VALUE"].toString(), 140, yPos);
          doc.text((item.QTY * item["ITEM VALUE"]).toString(), 170, yPos);
          // Determine the maximum height among the columns for the current row
          const maxColumnHeight = Math.max(
            doc.getTextDimensions(wrappedProductName).h,
            doc.getTextDimensions(item.QTY.toString()).h,
            doc.getTextDimensions(item["ITEM VALUE"].toString()).h,
            doc.getTextDimensions((item.QTY * item["ITEM VALUE"]).toString()).h
          );
          // Draw a dotted line after each item for separation
          yPos += maxColumnHeight + 5;
          doc.setLineWidth(0.5);
          doc.setLineDash([1, 2]); // Set a dotted pattern
          doc.line(20, yPos, 190, yPos);
          yPos += 5;
          const numericAmount = parseFloat(item.QTY * item["ITEM VALUE"]);
          totalAmount += numericAmount;
        })
      : listofitemstaken.forEach((item, index) => {
          yPos += 5;
          // Adjusted widths for columns
          const productNameWidth = 80;
          // Wrap the ProductName within the allocated space
          const wrappedProductName = doc.splitTextToSize(
            item.ProductName,
            productNameWidth
          );
          // Draw each column
          doc.text(wrappedProductName, 20, yPos);
          doc.text(item.quanity.toString(), 115, yPos);
          doc.text(item.rate.toString(), 140, yPos);
          doc.text(item.totalamount.toString(), 170, yPos);
          // Determine the maximum height among the columns for the current row
          const maxColumnHeight = Math.max(
            doc.getTextDimensions(wrappedProductName).h,
            doc.getTextDimensions(item.quanity.toString()).h,
            doc.getTextDimensions(item.rate.toString()).h,
            doc.getTextDimensions(item.totalamount.toString()).h
          );
          // Draw a dotted line after each item for separation
          yPos += maxColumnHeight + 5;
          doc.setLineWidth(0.5);
          doc.setLineDash([1, 2]); // Set a dotted pattern
          doc.line(20, yPos, 190, yPos);
          yPos += 5;
          const numericAmount = parseFloat(item.totalamount);
          totalAmount += numericAmount;
        });

    doc.setFontSize(15);
    yPos += 5;
    doc.text("Total Amount:", 130, yPos);
    doc.text(`${totalAmount.toFixed(2)}`, 170, yPos);
    yPos += 10;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 10;
    doc.text("This is not an official Receipt", 20, yPos);
    yPos += 10;
    doc.text(
      "Please Insist on the official Receipt after paying the bill",
      20,
      yPos
    );
    yPos += 10;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 10;
    doc.text("You Were Served by :", 20, yPos);
    doc.text(selservername ? selservername : "", 80, yPos);
    yPos += 10;
    doc.setLineWidth(0.5);
    doc.setLineDash([1, 2]); // Set a dotted pattern
    doc.line(20, yPos, 190, yPos);
    yPos += 10;
    doc.text("Mars Track ERP www.gbsafrica.net", 20, yPos);
    yPos += 10;
    doc.text("Powered by Genius Buisness System", 20, yPos);

    doc.save("CashSaleInvoice.pdf");
  };

  function handleReceipt(cashsaleno, total, vat, levy) {
    navigate("/Receipt", { state: { cashsaleno, total, vat, levy } });
  }

  //
  const getCompanyDetails = () => {
    axios
      .get(`${BASEURL}/getCompanyDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setcompCode(response.data[0].ORGCODE);
        setvatregno(response.data[0].ORG_VAT_REG_NO);
        setpinno(response.data[0].ORG_PIN_NO);
        setorgname(response.data[0].ORG_NAME);
        setadress1(response.data[0].ORG_ADDRESS_1);
        setadress2(response.data[0].ORG_ADDRESS_2);
        setadress3(response.data[0].ORG_ADDRESS_3);
        setcity(response.data[0].ORG_CITY);
        setcountry(response.data[0].ORG_COUNTRY);
      })
      .catch((error) => {
        console.error("Error fetching List Of Cash Sale History:", error);
      });
  };

  const getDetails = () => {
    // getCashSaleHistory
    axios
      .post(
        `${BASEURL}/getCashSaleHistory`,
        {
          DIVISON: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofCashSales(response.data);
        setlistofCashSales1(response.data);
        setfirstcashposno(response.data[0].INVNO);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching List Of Cash Sale History:", error);
        setisloading(false);
      });

    // getNewCashPOSNo
    axios
      .get(`${BASEURL}/newCashPosNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setnewCashPOSNo(response.data[0].INVNO);
      })
      .catch((error) => {
        console.error("Error fetching New Cash POS Number:", error);
      });

    // getListOfTables
    axios
      .post(
        `${BASEURL}/getListOfTables`,
        {
          division: divisionState,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistoftables(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Tables:", error);
      });

    // getListOfServedBy
    axios
      .get(`${BASEURL}/getListOfServedBy`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setlistofservers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Served By:", error);
      });

    // getProductDetails
    axios
      .get(`${BASEURL}/getProductDetails`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setListOfProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching List Of Products:", error);
      });
  };

  const fetchAllDetails = (cashposno) => {
    setShowTable(true);
    setCashSaleHistoric(true);
    setPdfData(null);
    axios
      .post(
        `${BASEURL}/getCashSaleDetailsInReceipt`,
        {
          invoice: cashposno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setlistofCashSales(response.data);
        setnewCashPOSNo(response.data[0].INVNO);
        setDate(response.data[0].INVDATE);
        setvatperc(16);
        sethvatamt(response.data[0]["VAT AMT"]);
        sethlevyamt(response.data[0]["LEVY AMT"]);
        sethlevyperc(response.data[0]["LEVY PERC"]);
        sethgrandtotal(response.data[0]["AMOUNT INCLUSIVE VAT"]);
        settillno(response.data[0].TILLNO);
        setselservername(response.data[0].WAITER);
        setseltable(response.data[0].TABLENO);
        setmobileno(response.data[0].MOBILENO);
        if (response.data[0].SEATINGDELIVERY) {
          if (response.data[0].SEATINGDELIVERY == "SEATING") {
            handleOptionSelect(1);
          } else {
            handleOptionSelect(2);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching List Of Cash Sale History:", error);
      });

    axios
      .post(
        `${BASEURL}/getCashSaleHistoryDetails`,
        {
          invoiceno: cashposno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethistoric(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale History Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getAlreadyPaidAmount`,
        {
          cashsaleno: cashposno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setamountpaid(response.data[0].ALREADYPAIDAMT);
      })
      .catch((error) => {
        console.error("Error fetching Amount Paid Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getHistoricAssociatedProduct`,
        {
          cashsaleno: cashposno,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setHistoricAssociated(response.data);
        setshowassociatedtable(true);
      })
      .catch((error) => {
        console.error("Error fetching Associated Product Details:", error);
      });
  };

  const handleButtonClick = async (operation) => {
    try {
      const latestPOSNo = await getLatestPOSNo();

      if (operation === "decrement" && newCashPOSNo > firstcashposno) {
        setselProductname("");
        setQuantity("");
        setvatamt("");
        setlevyamt("");
        setRate("");
        setlevyflag(false);
        setvatflag(false);
        setnewCashPOSNo(newCashPOSNo - 1);
        fetchAllDetails(newCashPOSNo - 1);
      } else if (operation === "increment" && newCashPOSNo < latestPOSNo) {
        setselProductname("");
        setQuantity("");
        setvatamt("");
        setlevyamt("");
        setRate("");
        setlevyflag(false);
        setvatflag(false);
        setnewCashPOSNo(newCashPOSNo + 1);
        fetchAllDetails(newCashPOSNo + 1);
      } else if (operation === "first") {
        setselProductname("");
        setQuantity("");
        setvatamt("");
        setlevyamt("");
        setRate("");
        setlevyflag(false);
        setvatflag(false);
        setnewCashPOSNo(firstcashposno);
        fetchAllDetails(firstcashposno);
      } else if (operation === "latest") {
        setselProductname("");
        setQuantity("");
        setvatamt("");
        setlevyamt("");
        setRate("");
        setlevyflag(false);
        setvatflag(false);
        setnewCashPOSNo(latestPOSNo - 1);
        fetchAllDetails(latestPOSNo - 1);
        // sethistoric([]);
        // setDate(new Date().toISOString());
        // setvatperc("");
        // sethvatamt("");
        // sethlevyamt("");
        // sethlevyperc("");
        // sethgrandtotal("");
        // settillno("");
        // setselservername("");
        // setseltable("");
        // setmobileno("");
        // setShowTable(false);
        // setCashSaleHistoric(false);
        // setSeatingFlag(null);
        // setselProductname("");
        // setQuantity("");
        // setvatamt("");
        // setlevyamt("");
        // setRate("");
        // setlevyflag(false);
        // setvatflag(false);
        // totalquantity = 0;
        // totalhquantity = 0;
        // grandtotal = 0;
        // totalvat = 0;
        // totallevy = 0;
      }
    } catch (error) {
      console.error("Error handling button click:", error);
    }
  };

  const getLatestPOSNo = async () => {
    try {
      const response = await axios.get(`${BASEURL}/newCashPosNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      });

      return response.data[0].INVNO;
    } catch (error) {
      console.error("Error fetching New Cash POS Number:", error);
    }
  };

  // Adding into Tables *********************************************************************************
  const AddCostItem = () => {
    if (!selProductname) {
      alert("Please select Product first ,Click on Search Button to Choose");
    } else if (Number(Quantity) <= 0) {
      alert("Quantity Can not be empty ,negative or 0");
      setQuantity("");
    } else {
      const newItem = {
        ProductCode: productcode,
        ProductName: selProductname,
        quanity: Quantity,
        rateexcl: Number(Rate) - (Number(levyamt) + Number(vatamt)),
        rate: Rate,
        totalamount: Number(Rate) * Number(Quantity),
        discount: 0.0,
        associated: associated,
        levyamt: levyamt,
        vatamt: vatamt,
      };

      const existingRowIndex = listofitemstaken.findIndex(
        (charge) =>
          charge.ProductName === newItem.ProductName &&
          charge.ProductCode === newItem.ProductCode
      );

      if (existingRowIndex !== -1) {
        const updatedItems = [...listofitemstaken];
        updatedItems[existingRowIndex] = newItem;
        setlistofitemstaken(updatedItems);
      } else {
        setlistofitemstaken([...listofitemstaken, newItem]);
      }

      setvatperc(16);
      setlevyperc(2);
      setShowTable(true);
      clearallinputs();
    }
  };

  const RemoveItems = () => {
    const updatedValues = [...listofitemstaken];
    if (updatedValues.length === 0) {
      alert("There is no Items to be removed");
      setShowTable(false);
    } else {
      if (
        selectedRowIndex !== null &&
        selectedRowIndex >= 0 &&
        selectedRowIndex < updatedValues.length
      ) {
        updatedValues.splice(selectedRowIndex, 1);
        setSelectedRowIndex(null);
      } else {
        updatedValues.pop();
      }
      setlistofitemstaken(updatedValues);
      if (updatedValues.length === 0) {
        setShowTable(false);
        setvatperc("");
        setlevyperc("");
      }
    }

    const associated = [...ListOfAssociatedProducts];
    if (associated.length === 0) {
      setshowassociatedtable(false);
      setshowassociated(false);
    } else {
      associated.pop();
      setListOfAssociatedProducts(associated);
      if (associated.length === 0) {
        setshowassociatedtable(false);
        setshowassociated(false);
      }
    }
  };

  const clearallinputs = () => {
    setselProductname("");
    setQuantity("");
    setRate("");
    setlevyamt("");
    setlevyflag(false);
    setvatamt("");
    setvatflag(false);
  };

  // ******************************* POP Up *************************************************
  const handleSearchClick = () => {
    // Open the popup window
    setPopupOpen(true);
  };

  const handleRowClick = (row) => {
    setActualQuantity(row.STOCKQTY);
    setSelectedRow(row);
    setQuantity(1);
    setRate(row.SELLINGPRICE);
    setProductCode(row.OM_ITEM_CODE);
    axios
      .post(
        `${BASEURL}/getAssociatedProduct`,
        {
          itemcode: row.OM_ITEM_CODE,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            axios
              .post(
                `${BASEURL}/getAssociatedDetails`,
                {
                  itemcode: response.data[i].ASSOCIATEDITEMCODE,
                },
                {
                  headers: {
                    "auth-token": authToken,
                    "session-token": sessiontoken,
                  },
                }
              )
              .then((response1) => {
                setListOfAssociatedProducts(response1.data);
              })
              .catch((error) => {
                console.error(
                  "Error fetching List Of Associated Products:",
                  error
                );
              });
          }
          setassociated("yes");
          setshowassociatedtable(true);
        } else {
          setassociated("no");
          setshowassociatedtable(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching List Of Associated Products:", error);
      });

    setselProductname(row.OM_ITEM_DESCRIPTION);
    setlevyflag(row.LEVYAPPLICABLE == 1 ? true : false);
    setvatflag(row.VATABLE == 1 ? true : false);
    if (row.LEVYAPPLICABLE == 1 && row.VATABLE == 1) {
      setlevyamt(((row.SELLINGPRICE * 1) / 118) * 2);
      setvatamt(row.SELLINGPRICE - row.SELLINGPRICE / 1.16);
    } else if (row.LEVYAPPLICABLE == 1 && row.VATABLE == 0) {
      setlevyamt(((row.SELLINGPRICE * 1) / 102) * 2);
      setvatamt(0);
    } else if (row.LEVYAPPLICABLE == 0 && row.VATABLE == 1) {
      setlevyamt(0);
      setvatamt(row.SELLINGPRICE - row.SELLINGPRICE / 1.16);
    } else if (row.LEVYAPPLICABLE == 0 && row.VATABLE == 0) {
      setlevyamt(0);
      setvatamt(0);
    }

    setPopupOpen(false);
  };

  const handleVATLEVY = (value) => {
    if (levyflag == true && vatflag == true) {
      setlevyamt(((Rate * value) / 118) * 2);
      setvatamt(Number(Rate) * value - (Number(Rate) * value) / 1.16);
    } else if (levyflag == true && vatflag == false) {
      setlevyamt(((Rate * value) / 102) * 2);
      setvatamt(0);
    } else if (levyflag == false && vatflag == true) {
      setlevyamt(0);
      setvatamt(Number(Rate) * value - (Number(Rate) * value) / 1.16);
    } else if (levyflag == false && vatflag == false) {
      setlevyamt(0);
      setvatamt(0);
    }
  };

  const fetchBackItems = (value) => {
    setselProductname(value["ITEM NAME"] ? value["ITEM NAME"] : "");
    setQuantity(value.QTY ? value.QTY : "");
    setvatamt(value.VATAMOUNT ? value.VATAMOUNT : "");
    setlevyamt(value.LEVYAMOUNT ? value.LEVYAMOUNT : "");
    setRate(value["ITEM VALUE"] ? value["ITEM VALUE"] : "");
    setlevyflag(value.LEVYAMOUNT ? true : false);
    setvatflag(value.VATAMOUNT ? true : false);
  };

  const fetchBackItems1 = (value, index) => {
    setProductCode(value.ProductCode);
    setselProductname(value.ProductName ? value.ProductName : "");
    setQuantity(value.quanity ? value.quanity : "");
    setvatamt(value.vatamt ? value.vatamt : "");
    setlevyamt(value.levyamt ? value.levyamt : "");
    setRate(value.rate ? value.rate : "");
    setlevyflag(value.levyamt ? true : false);
    setvatflag(value.vatamt ? true : false);
    setSelectedRowIndex(index);
  };

  const handleCashSaleClick = (row) => {
    setShowTable(true);
    setCashSaleHistoric(true);
    setPdfData(null);
    setnewCashPOSNo(row.INVNO);
    setDate(row.INVDATE);
    setvatperc(16);
    sethvatamt(row["VAT AMT"]);
    sethlevyamt(row["LEVY AMT"]);
    sethlevyperc(row["LEVY PERC"]);
    sethgrandtotal(row["AMOUNT INCLUSIVE VAT"]);
    settillno(row.TILLNO);
    setselservername(row.WAITER);
    setseltable(row.TABLENO);
    setmobileno(row.MOBILENO);
    if (row.SEATINGDELIVERY) {
      if (row.SEATINGDELIVERY == "SEATING") {
        handleOptionSelect(1);
      } else {
        handleOptionSelect(2);
      }
    }
    axios
      .post(
        `${BASEURL}/getCashSaleHistoryDetails`,
        {
          invoiceno: row.INVNO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        sethistoric(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Cash Sale History Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getAlreadyPaidAmount`,
        {
          cashsaleno: row.INVNO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setamountpaid(response.data[0].ALREADYPAIDAMT);
      })
      .catch((error) => {
        console.error("Error fetching Amount Paid Details:", error);
      });

    axios
      .post(
        `${BASEURL}/getHistoricAssociatedProduct`,
        {
          cashsaleno: row.INVNO,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((response) => {
        setHistoricAssociated(response.data);
        setshowassociatedtable(true);
      })
      .catch((error) => {
        console.error(
          "Error fetching Historic Associated Product Details:",
          error
        );
      });
  };
  // *************** Save Procedure ********************************
  async function SaveHeaders() {
    try {
      const response = await axios.post(
        `${BASEURL}/InsertCashSaleHeader`,
        {
          INVNO: newCashPOSNo,
          INVDATE: date,
          INVSALESLOC: division,
          INVVATAMT: totalvat,
          INVAMOUNT: grandtotal,
          CREATEDTIME: currentTime,
          CREATED_BY: userState.emailId.split("@")[0].toUpperCase(),
          INVCREATEDDATE: date,
          CREATEDCOMPCODE: compCode ? compCode : "",
          CREATEDDIVISIONCODE: division,
          INVMOBILENO: mobileno ? mobileno : "",
          INVAMOUNTPAID: amountpaid,
          INVTABLENUMBER: seltable,
          INVTABLEWAITERSERVED: selservername,
          INVLEVYAMOUNT: totallevy,
          LEVYPERC: levyperc,
          INVTILLNO: "",
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      );

      if (response.status === 200) {
        console.log(`Headers saved successfully`);
      } else {
        console.error(`Error while saving headers`);
      }
    } catch (error) {
      console.error(`Error while saving row headers`, error);
    }
  }

  async function SaveDetails() {
    for (let i = 0; i < listofitemstaken.length; i++) {
      const value = listofitemstaken[i];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertCashSaleDetails`,
          {
            INVNO: newCashPOSNo,
            INVDATE: date,
            INVITEMCODE: value.ProductCode,
            INVITEMNAME: value.ProductName,
            INVQTY: value.quanity,
            INVRATE: value.rateexcl,
            INVRATEINCLUSIVE: value.rate,
            INVITEMDISC: value.discount,
            INVITEMVATAMOUNT: value.vatamt,
            INVITEMLEVY: value.levyamt,
            INVITEMVALUE: value.totalamount,
            INVDIVISION: division,
            INVCREATEDBY: userState.emailId.split("@")[0].toUpperCase(),
            INVCREATEDDATE: date,
            INVASSOCIATED: value.associated === "yes" ? 1 : 0,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${i + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${i + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${i + 1}`, error);
      }
    }
  }

  async function SaveAssociated() {
    for (let i = 0; i < ListOfAssociatedProducts.length; i++) {
      const value = ListOfAssociatedProducts[i];
      try {
        const response = await axios.post(
          `${BASEURL}/InsertAssociatedProducts`,
          {
            TRANSTYPE: "CASHSALEPOSASSOCIATED",
            TRANSNUMBER: newCashPOSNo,
            TRANSREFDOCTYPE: "",
            TRANSDATE: date,
            ITEMCODE: value.OM_ITEM_CODE,
            ITEMVENDORPARTNO: value.OM_ITEM_DESCRIPTION,
            ITEMNAME: value.OM_ITEM_DESCRIPTION,
            ITEMUOM: value.UOM,
            ITEMQTY: value.STOCKQTY,
            ITEMISSUEDATE: date,
            ITEMTRANSCREATEDDATE: date,
            ITEMTRANSCREATEDBY: userState.emailId.split("@")[0].toUpperCase(),
            ITEMTRANSCREATEDCOMPCODE: compCode ? compCode : "",
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        );

        if (response.status === 200) {
          console.log(`Row ${i + 1} saved successfully`);
        } else {
          console.error(`Error while saving row ${i + 1}`);
        }
      } catch (error) {
        console.error(`Error while saving row ${i + 1}`, error);
      }
    }
  }

  // Crystal Reports
  function generateReport() {
    setPdfData(null);

    if (!newCashPOSNo) {
      alert("Please select a Cash POS NO");
      return;
    }
    setLoadingScreenBlock(true);
    axios
      .post(
        `${BASEURL_REPORTS}/getReports`,
        {
          REPORTNAME: reportName,
          DOCNO: Number(newCashPOSNo),
          REPORTPATH: `${reportPath?.replace(/\\\\/g, "\\")}`,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log(res);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfData(fileURL);
        setLoadingScreenBlock(false);
      })
      .catch((err) => {
        console.log("Error generating report:", err);
        setLoadingScreenBlock(false);
      });
  }

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfData;
    a.download = "generated_report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <>
      <Navbar />
      {isloading ? (
        <Loading />
      ) : (
        <div>
          <div className="w-full  bg-[#002e62db] flex flex-row px-3 py-1 my-2 justify-between">
            <p className="text-white ml-2 lg:mr-14">Mars Track Point of Sale</p>
            <p className="text-white mr-2 lg:ml-24">
              {moment(date.split("T")[0]).format("DD/MM/YYYY")}
            </p>
            <p className="text-white lg:mr-48">{currentTime}</p>
            {/* <p className="text-white lg:ml-14">Location :</p>
            <p className="text-white lg:mr-14 ml-2">TWO RIVERS POS</p> */}
            <div className="flex">
              <p className="text-white">Division :</p>
              {/* <p className="text-white lg:ml-4">{division ? division : ""}</p> */}
              <select
                onChange={(e) => {
                  setdivsion(e.target.value);
                  dispatch(setDivision(e.target.value));
                }}
                className="w-48 h-6 border-black border ml-2 mr-2 rounded"
              >
                <option>{divisionState}</option>
                {userdivisionRights.map((item) =>
                  item.OM_USER_DIVISION !== divisionState ? (
                    <option
                      key={item.OM_USER_DIVISION}
                      value={item.OM_USER_DIVISION}
                    >
                      {item.OM_USER_DIVISION}
                    </option>
                  ) : null
                )}
              </select>
            </div>
          </div>
          <div className="w-full h-auto ">
            <div className="flex felx-row my-2 ml-[10px]">
              <button
                className="  border border-black bg-[#002d62] text-white rounded-md px-3 py-1  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  handleButtonClick("first");
                }}
              >
                {"<<"}
              </button>
              <button
                className="  border border-black bg-[#002d62] text-white rounded-md px-3  py-1 mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  handleButtonClick("decrement");
                }}
              >
                {"<"}
              </button>
              <button
                className="  border border-black bg-[#002d62] text-white rounded-md px-3 py-1  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  handleButtonClick("increment");
                }}
              >
                {">"}
              </button>
              <button
                className="  border border-black bg-[#002d62] text-white rounded-md px-3  py-1 mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  handleButtonClick("latest");
                }}
              >
                {">>"}
              </button>
            </div>
            {/* first row */}
            <section className="lg:flex">
              <div className="flex felx-row mb-2 mt-2">
                <p className="text-red-600 ml-2">Credit Note No:</p>
                <input
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={newCashPOSNo}
                  readOnly
                />

                <button
                  className="flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    setcashsalepopup(true);
                  }}
                >
                  ...
                </button>

                {cashsalepopup && (
                  <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
                    <div className="flex flex-row mt-2">
                      <p className="text-red-600 ml-2">Search:</p>
                      <input
                        className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        type="text"
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      ></input>
                      <p className="text-red-600 ml-2">Start Date:</p>
                      <input
                        type="date"
                        className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setstartdate(e.target.value);
                        }}
                        value={startdate.split("T")[0]}
                      ></input>
                      <p className="text-red-600 ml-2">End Date:</p>
                      <input
                        type="date"
                        className="w-36 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                        onChange={(e) => {
                          setEnddate(e.target.value);
                        }}
                        value={enddate.split("T")[0]}
                      ></input>
                    </div>
                    <button
                      className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                      onClick={() => setcashsalepopup(false)}
                    >
                      Close
                    </button>

                    <aside className="table__pos__app text-sm ">
                      <table class="custom-table">
                        <thead class="header-cell">
                          <tr>
                            <td className="pl-2">Cash Sale No</td>
                            <td className="text-left">Date</td>
                            <td className="text-left">Sales Location</td>
                            <td className="text-left">Customer Name</td>
                            <td className="text-right">Financial Year</td>
                            <td className="text-right">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                          {filtHistoricCash.map((row, index) => (
                            <tr
                              key={row.INVNO}
                              onClick={() => {
                                setcashsalepopup(false);
                                handleCashSaleClick(row);
                              }}
                              className={
                                index < listofCashSales1.length
                                  ? "border-b cursor-pointer text-sm table-row"
                                  : ""
                              }
                            >
                              <td className="text-right pr-12">
                                {row.INVNO ? row.INVNO : ""}
                              </td>
                              <td className="text-left">
                                {row.INVDATE
                                  ? moment(row.INVDATE.split("T")[0]).format(
                                      "DD/MM/YYYY"
                                    )
                                  : ""}
                              </td>
                              <td className="text-left">
                                {row["SALES LOC"] ? row["SALES LOC"] : ""}
                              </td>
                              <td className="text-left">
                                {row["CUSTOMER NAME"]
                                  ? row["CUSTOMER NAME"]
                                  : ""}
                              </td>
                              <td className="text-right">
                                {row["FINANCIAL YEAR"]
                                  ? row["FINANCIAL YEAR"]
                                  : ""}
                              </td>
                              <td className="text-right">
                                {row["AMOUNT INCLUSIVE VAT"]
                                  ? row["AMOUNT INCLUSIVE VAT"].toLocaleString(
                                      undefined,
                                      {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      }
                                    )
                                  : ""}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </aside>
                  </div>
                )}
                <p className="text-red-600 ml-4 mr-2">Till ID:</p>
                <input
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={tillno}
                  onChange={(e) => {
                    settillno(e.target.value);
                  }}
                  disabled={CashSaleHistoric == true}
                ></input>
                <p className="text-red-600 ml-4 mr-2">User ID:</p>
                <input
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={userid ? userid : ""}
                  readOnly
                ></input>
              </div>
              <div className="flex felx-row mb-2 mt-2">
                <input
                  type="radio"
                  className="ml-8 mr-2"
                  checked={seatingFlag === 1}
                  onChange={() => handleOptionSelect(1)}
                  disabled={CashSaleHistoric == true}
                ></input>
                <p className="text-red-600 mr-2">Seating</p>
                <input
                  type="radio"
                  className="ml-2 mr-2"
                  checked={seatingFlag === 2}
                  onChange={() => {
                    setseltable("");
                    handleOptionSelect(2);
                  }}
                  disabled={CashSaleHistoric == true}
                ></input>
                <p className="text-red-600 mr-2">Take away</p>
                <input
                  type="radio"
                  className="ml-2 mr-2"
                  checked={seatingFlag === 3}
                  onChange={() => {
                    setseltable("");
                    handleOptionSelect(3);
                  }}
                  disabled={CashSaleHistoric == true}
                ></input>
                <p className="text-red-600 mr-2">Delivery</p>
                <p className="text-red-600 ml-16">Date:</p>
                <input
                  type="date"
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={date.split("T")[0]}
                  onChange={(e) => {
                    setDate(e.target.value);
                  }}
                  //   disabled={CashSaleHistoric == true}
                ></input>
              </div>
            </section>
            {/* second row */}
            <section className=" lg:flex">
              <div className="flex felx-row mb-2 mt-2">
                <p className="text-red-600 ml-2 mr-5 w-[130px] lg:w-[83px]">
                  Table No:
                </p>
                <div className="w-[200px] lg:w-[320px]">
                  <Select
                    styles={customStyless}
                    onChange={(e) => {
                      setseltable(e.value);
                    }}
                    value={
                      seltable
                        ? { label: seltable, value: seltable }
                        : { label: "", value: "" }
                    }
                    options={listoftables.map((item) => ({
                      label: item.TABLENUMBER,
                      value: item.TABLENUMBER,
                    }))}
                    isDisabled={
                      seatingFlag === 2 ||
                      seatingFlag === 3 ||
                      seatingFlag === null ||
                      CashSaleHistoric == true
                    }
                  />
                </div>
                <p className="text-red-600 ml-16 mr-3">Served by:</p>
                <div className="w-[200px] lg:w-[320px]">
                  {" "}
                  <Select
                    styles={customStyless}
                    onChange={(e) => {
                      setselservername(e.value);
                    }}
                    value={
                      selservername
                        ? { label: selservername, value: selservername }
                        : { label: "", value: "" }
                    }
                    options={listofservers.map((item) => ({
                      label: item.WAITER,
                      value: item.WAITER,
                    }))}
                    isDisabled={
                      CashSaleHistoric == true || seatingFlag === null
                    }
                  />
                </div>
              </div>
              <div className="flex felx-row mb-2 mt-2">
                <p className="text-red-600 ml-2 w-[140px] lg:w-[auto] lg:ml-16">
                  Received Amount:
                </p>
                <input
                  className="w-32 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  disabled={CashSaleHistoric == false}
                  readOnly
                ></input>
              </div>
            </section>
          </div>
          <section className=" lg:flex">
            <div className="flex flex-row mt-2 ml-2">
              <p className="text-red-600 mr-2 w-[100px] lg:w-[auto]">Product</p>
              <input
                className="w-64 h-8 border border-black ml-10 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={selProductname}
                readOnly
              ></input>
              <button
                className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  if (CashSaleHistoric == true) {
                    alert(
                      "Cannot Add Product when historic details are fetched"
                    );
                  } else if (seatingFlag === null) {
                    alert("Kindly Choose Seating Or TakeAway Or Delivery");
                  } else if (seatingFlag === 1 && !seltable) {
                    alert("Kindly Select Table No");
                  } else if (seatingFlag != null && !selservername) {
                    alert("Kindly Select the Waiter");
                  } else {
                    handleSearchClick();
                  }
                }}
              >
                Search
              </button>
            </div>{" "}
            <div className="flex flex-row mt-2 ml-2">
              <p className="text-red-600 mr-1">Quantity:</p>
              <input
                className="w-16 h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                onChange={(e) => {
                  if (!selProductname) {
                    alert("Kindly choose the product first");
                  } else {
                    if (e.target.value > actualQuantity) {
                      alert("You are exceeding the quantity in stock");
                      setQuantity(1);
                      handleVATLEVY(1);
                    } else {
                      setQuantity(e.target.value);
                      handleVATLEVY(e.target.value);
                    }
                  }
                }}
                value={Quantity}
                disabled={CashSaleHistoric == true}
              ></input>
              <p className="text-red-600 ml-1 mr-1">Rate:</p>
              <input
                className="w-20 h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={Rate.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                readOnly
              ></input>
              {/* Levy Amount */}
              <p className="text-red-600 mr-1 ml-1">Levy Amt:</p>
              <input
                className="w-14 h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500 "
                value={
                  levyamt != null
                    ? levyamt.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
            </div>
            <div className="flex flex-row mt-2 ml-2">
              <input
                className="ml-2"
                type="checkbox"
                checked={levyflag}
                readOnly
              ></input>
              <p className="ml-1">Levy Applicable</p>
              {/* Vat Amount */}
              <p className="text-red-600 mr-1 ml-1">Vat Amt:</p>
              <input
                className="w-14  h-8 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={
                  vatamt != null
                    ? vatamt.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : ""
                }
                readOnly
              ></input>
              <input
                className="ml-1"
                type="checkbox"
                checked={vatflag}
                readOnly
              ></input>
              <p className="ml-1">Vat Applicable</p>
            </div>
          </section>
          {isPopupOpen && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
              <div className="flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64 h-8 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={searchprod}
                  onChange={(e) => {
                    setSearchprod(e.target.value);
                  }}
                ></input>
              </div>
              <button
                className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 ml-2 mr-2 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => setPopupOpen(false)}
              >
                Close
              </button>

              <aside className="table__pos__app text-sm">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td className="text-left">Product Code</td>
                      <td className="text-left">Product Name</td>
                      <td className="text-right">Quantity</td>
                      <td className="text-right">Rate</td>
                      <td className="text-left pl-4">UOM</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtProducts.map((row, index) => (
                      <tr
                        key={row.OM_ITEM_CODE}
                        onClick={() => handleRowClick(row)}
                        className={
                          index < listofProducts.length - 1
                            ? "border-b cursor-pointer text-sm table-row"
                            : ""
                        }
                      >
                        <td className="text-left">
                          {row.OM_ITEM_CODE ? row.OM_ITEM_CODE : ""}
                        </td>
                        <td
                          className="text-left"
                          style={{ maxWidth: "300px", minWidth: "200px" }}
                        >
                          {row.OM_ITEM_DESCRIPTION
                            ? row.OM_ITEM_DESCRIPTION
                            : ""}
                        </td>
                        <td className="text-right">
                          {row.STOCKQTY ? row.STOCKQTY : ""}
                        </td>
                        <td className="text-right">
                          {row.SELLINGPRICE
                            ? row.SELLINGPRICE.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : "0.00"}
                        </td>
                        <td className="text-left pl-4">
                          {row.UOM ? row.UOM : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          )}
          <div className="flex flex-row mt-2 ml-2 mb-2 justify-between">
            <div className="flex flex-row">
              <button
                className="flex w-[100px] items-center justify-center border border-black bg-gray-600 text-white rounded-md px-3 py-1 hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  if (listofitemstaken.length > 0 || historic.length > 0) {
                    setshowassociated(false);
                    setShowTable(true);
                  } else {
                    alert("Cannot Access when there is no data");
                  }
                }}
              >
                Items
              </button>
              <button
                className="flex w-[100px] mx-2 items-center justify-center border border-black bg-gray-600 text-white rounded-md px-3 py-1 hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  if (
                    ListOfAssociatedProducts.length > 0 ||
                    historicAssociated.length > 0
                  ) {
                    if (showassociatedtable === true) {
                      setshowassociated(true);
                      setShowTable(false);
                    } else if (showassociatedtable === false) {
                      setshowassociated(false);
                      setShowTable(true);
                    }
                  } else {
                    alert("Cannot Access when there is no data");
                  }
                }}
              >
                Associated
              </button>
            </div>
            <div className="flex flex-row">
              <button
                className="flex w-[100px] items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3 py-1 mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  if (CashSaleHistoric == true) {
                    alert("Can not modify when Historic details are fetched");
                  } else {
                    AddCostItem();
                  }
                }}
              >
                Add
              </button>
              <button
                className="flex w-[100px] items-center justify-center border border-black bg-[#002d62] mr-1 text-white rounded-md px-3 py-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => {
                  if (CashSaleHistoric == true) {
                    alert("Can not modify when Historic details are fetched");
                  } else {
                    RemoveItems();
                  }
                }}
              >
                Remove
              </button>
            </div>
          </div>

          <div className="w-full h-auto p-1">
            {ShowTable && (
              <aside className="table__pos__app text-sm ">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td className="text-left">Product Code</td>
                      <td className="text-left">Product Name</td>
                      <td className="text-right">Quantity</td>
                      <td className="text-right">Rate (Exclusive)</td>
                      <td className="text-right">VAT Amount</td>
                      <td className="text-right">Levy Amount</td>
                      <td className="text-right">Rate (Inclusive)</td>
                      <td className="text-right">Total Amount</td>
                      <td className="text-right">Discount</td>
                      <td className="text-left pl-4">Associated</td>
                    </tr>
                  </thead>
                  <tbody>
                    {historic.length > 0
                      ? historic.map((value, index) => (
                          <tr
                            className={
                              index < historic.length
                                ? "border-b cursor-pointer text-sm table-row"
                                : ""
                            }
                            onClick={() => {
                              fetchBackItems(value);
                            }}
                          >
                            <td>
                              {value["ITEM CODE"] ? value["ITEM CODE"] : ""}
                            </td>
                            <td style={{ maxWidth: "250px" }}>
                              {value["ITEM NAME"] ? value["ITEM NAME"] : ""}
                            </td>
                            <td className="text-right">
                              {value.QTY ? value.QTY : ""}
                            </td>
                            <td className="text-right">
                              {value["ITEM VALUE EXC VAT"]
                                ? value["ITEM VALUE EXC VAT"].toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.VATAMOUNT
                                ? value.VATAMOUNT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.LEVYAMOUNT
                                ? value.LEVYAMOUNT.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>

                            <td className="text-right">
                              {value["ITEM VALUE"]
                                ? value["ITEM VALUE"].toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }
                                  )
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.QTY
                                ? (
                                    Number(value.QTY) *
                                    Number(value["ITEM VALUE"])
                                  ).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value["ITEM DISC"]
                                ? value["ITEM DISC"].toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-left pl-4">
                              {value.OT_INV_ITEM_ASSOCIATED == 1 ? "YES" : "NO"}
                            </td>
                          </tr>
                        ))
                      : listofitemstaken.map((value, index) => (
                          <tr
                            className={
                              index < listofProducts.length - 1
                                ? "border-b cursor-pointer text-sm"
                                : ""
                            }
                            onClick={() => {
                              fetchBackItems1(value, index);
                            }}
                          >
                            <td>
                              {value.ProductCode ? value.ProductCode : ""}
                            </td>
                            <td>
                              {value.ProductName ? value.ProductName : ""}
                            </td>
                            <td className="text-right">
                              {value.quanity ? value.quanity : ""}
                            </td>
                            <td className="text-right">
                              {value.rateexcl
                                ? value.rateexcl.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.vatamt != null
                                ? value.vatamt.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.levyamt != null
                                ? value.levyamt.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>

                            <td className="text-right">
                              {value.rate
                                ? value.rate.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.totalamount
                                ? value.totalamount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td className="text-right">
                              {value.discount
                                ? value.discount.toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : 0.0}
                            </td>
                            <td>{value.associated ? value.associated : ""}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </aside>
            )}
            {showassociated && (
              <aside className="table__pos__app text-sm">
                <table class="custom-table">
                  <thead class="header-cell">
                    <tr>
                      <td>Product Code</td>
                      <td>Product Name</td>
                      <td>UOM</td>
                      <td>Quantity</td>
                    </tr>
                  </thead>
                  <tbody>
                    {historicAssociated.length > 0
                      ? historicAssociated.map((value, index) => (
                          <tr
                            className={
                              index < historicAssociated.length - 1
                                ? "border-b cursor-pointer text-sm table-row"
                                : ""
                            }
                          >
                            <td>{value.ITEMCODE ? value.ITEMCODE : ""}</td>
                            <td>{value.ITEMNAME ? value.ITEMNAME : ""}</td>
                            <td>{value.UOM ? value.UOM : ""}</td>
                            <td>{value.QTY ? value.QTY : ""}</td>
                          </tr>
                        ))
                      : ListOfAssociatedProducts.map((value, index) => (
                          <tr
                            className={
                              index < ListOfAssociatedProducts.length - 1
                                ? "border-b cursor-pointer text-sm"
                                : ""
                            }
                          >
                            <td>
                              {value.OM_ITEM_CODE ? value.OM_ITEM_CODE : ""}
                            </td>
                            <td>
                              {value.OM_ITEM_DESCRIPTION
                                ? value.OM_ITEM_DESCRIPTION
                                : ""}
                            </td>
                            <td>{value.UOM ? value.UOM : ""}</td>
                            <td>{value.STOCKQTY ? value.STOCKQTY : ""}</td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </aside>
            )}
          </div>
          <div className="w-full h-auto lg:flex flex-row">
            {/* Left Side */}
            <div>
              <div className="flex flex-row mt-4 mr-2 w-auto">
                <button
                  className="flex w-[145px] items-center justify-center border border-black bg-red-950 mr-2 ml-1 text-white rounded-md px-3 py-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  New Transaction
                </button>
                <button
                  className="flex w-[145px] items-center justify-center border border-black bg-pink-600 mr-2 text-white rounded-md px-3 py-1 hover:bg-pink-700 focus:outline-none focus:ring focus:border-blue-300"
                  onClick={async () => {
                    if (CashSaleHistoric == true) {
                      alert("Can not save when historic details are fetched");
                    } else {
                      if (!newCashPOSNo) {
                        alert("Kindly Provide Cash POS No");
                      } else if (
                        listofitemstaken.length <= 0 ||
                        !grandtotal ||
                        !totalvat ||
                        !totallevy ||
                        !levyperc ||
                        !vatperc
                      ) {
                        alert("Kindly Add Items first");
                      } else {
                        await SaveHeaders();
                        await SaveDetails();
                        if (ListOfAssociatedProducts.length > 0) {
                          await SaveAssociated();
                        }
                        alert("Details Saved Succesfully");
                        window.location.reload();
                      }
                    }
                  }}
                >
                  Save
                </button>
                <button
                  className="flex w-[145px] items-center justify-center border border-black bg-blue-600 mr-2 text-white rounded-md px-3 py-1 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    if (CashSaleHistoric == true) {
                      if (!newCashPOSNo) {
                        alert("Kindly Provide Cash POS No");
                      } else if (historic.length <= 0) {
                        alert("there is no item");
                      } else {
                        generateInvoicePDF();
                      }
                    } else {
                      if (!newCashPOSNo) {
                        alert("Kindly Provide Cash POS No");
                      } else if (
                        listofitemstaken.length <= 0 ||
                        !grandtotal ||
                        !totalvat ||
                        !totallevy ||
                        !levyperc ||
                        !vatperc
                      ) {
                        alert("Kindly Add Items first");
                      } else if (!amountpaid) {
                        alert("Kindly Supply amount paid");
                      } else {
                        generateInvoicePDF();
                      }
                    }
                  }}
                >
                  Print Cash sale
                </button>
                <button className="flex w-[145px] items-center justify-center border border-black bg-indigo-900 mr-2 text-white rounded-md px-3 py-1 hover:bg-indigo-800 focus:outline-none focus:ring focus:border-blue-300">
                  Sales for the day
                </button>
              </div>
              <div className="flex flex-row mt-2 mr-2 w-auto">
                <button
                  className="flex  w-[145px] items-center justify-center border border-black bg-gray-600 mr-2 ml-1 text-white rounded-md px-3 py-1 hover:bg-gray-700 focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    if (Number(hgrandtotal) > Number(amountpaid)) {
                      handleReceipt(
                        newCashPOSNo,
                        hgrandtotal,
                        hvatamt,
                        hlevyamt
                      );
                    } else {
                      alert("Total Amount already paid!!!");
                    }
                  }}
                >
                  Receive Money
                </button>
                <button className="flex w-[145px] items-center justify-center border border-black bg-sky-600 mr-2 text-white rounded-md px-3 py-1 hover:bg-sky-700 focus:outline-none focus:ring focus:border-blue-300">
                  Cancel Sale
                </button>
                <button className="flex w-[145px] items-center justify-center border border-black bg-green-400 mr-2 text-white rounded-md px-3 py-1 hover:bg-green-500 focus:outline-none focus:ring focus:border-blue-300">
                  Close Screen
                </button>
                <button className="flex w-[145px] items-center justify-center border border-black bg-green-600 text-white rounded-md px-3 py-1 hover:bg-green-700 focus:outline-none focus:ring focus:border-blue-300">
                  Unpaid Bills
                </button>
              </div>
              <div className="flex flex-row mt-2 mr-2 w-auto">
                <button
                  className="flex w-[145px] items-center justify-center border border-black bg-blue-600 ml-1 text-white rounded-md px-3 py-1 hover:bg-blue-700 focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    if (CashSaleHistoric === false) {
                      alert("Kindly Save the Cash Sale first");
                    } else {
                      generateReport();
                    }
                  }}
                >
                  Generate Report
                </button>
              </div>
            </div>
            {/* Right Side */}
            <div className="mt-2 ml-2 lg:ml-12">
              <div className="flex flex-row">
                <p>Number of Items:</p>
                <input
                  className="w-14 h-6 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500 "
                  value={
                    historic.length > 0
                      ? historic.length
                      : listofitemstaken.length > 0
                      ? listofitemstaken.length
                      : ""
                  }
                  readOnly
                ></input>
                <p className="ml-[175px]">Quantity:</p>
                <input
                  className="w-20 h-6 ml-2 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500 "
                  value={
                    historic.length > 0
                      ? totalhquantity
                      : totalquantity > 0
                      ? totalquantity
                      : ""
                  }
                  readOnly
                ></input>
              </div>
              <div className="flex flex-row mt-2">
                <p>Vat % and Amt:</p>
                <input
                  className="w-14 h-6 ml-6 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    vatperc != null
                      ? vatperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                ></input>
                <input
                  className="w-20 h-6 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hvatamt
                      ? hvatamt.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : totalvat != null
                      ? totalvat.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                ></input>
                <p className="ml-20">Mobile No:</p>
                <input
                  className="w-32 h-6 ml-1 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="number"
                  value={
                    mobileno
                      ? mobileno.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  onChange={(e) => {
                    setmobileno(e.target.value);
                  }}
                  disabled={CashSaleHistoric == true}
                ></input>
              </div>
              <div className="flex flex-row mt-2">
                <p>Levy % and Amt:</p>
                <input
                  className="w-14 h-6 ml-4 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hlevyperc
                      ? hlevyperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : levyperc != null
                      ? levyperc.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                ></input>
                <input
                  className="w-20 h-6 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hlevyamt
                      ? hlevyamt.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : totallevy != null
                      ? totallevy.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                ></input>
                <p className="ml-16">Amount Paid:</p>
                <input
                  className="w-32 h-6 ml-1 border border-black p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={
                    amountpaid
                      ? amountpaid.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  disabled={CashSaleHistoric == false}
                  readOnly
                ></input>
              </div>
              <div className="flex flex-row mt-1 mb-2">
                <p>Grand Total:</p>
                <input
                  className="w-24 h-6 border border-black ml-12 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    hgrandtotal
                      ? hgrandtotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : grandtotal
                      ? grandtotal.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : ""
                  }
                  readOnly
                ></input>
                <button className="flex items-center justify-center border border-black bg-sky-600 ml-32 text-white rounded-md px-3 py-1 hover:bg-sky-700 focus:outline-none focus:ring focus:border-blue-300">
                  View Receipt's Breakdown
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Report Generation */}
      {LoadingScreenBlock ? (
        <LoadingSpinner />
      ) : (
        <>
          {pdfData ? (
            <div className="w-full justify-center items-center min-h-screen p-4">
              <section className="lg:flex lg:flex-col bg-white p-4 ga  shadow-lg rounded-md w-full min-h-[500px]">
                <div>
                  {/* <div className="flex items-center gap-4"></div> */}

                  <section className="lg:hidden block">
                    {pdfData && (
                      <button
                        className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
                        onClick={handleDownload}
                      >
                        Download PDF
                      </button>
                    )}
                  </section>

                  <section className="hidden lg:block">
                    {pdfData ? (
                      <div className="mt-4">
                        <h2 className="text-lg font-bold">Generated Report</h2>
                        <iframe
                          src={pdfData}
                          title="Generated Report"
                          width="100%"
                          height="600px"
                        ></iframe>
                      </div>
                    ) : (
                      <p className="text-2xl font-bold my-4 text-center">
                        No report available.
                      </p>
                    )}
                  </section>
                </div>
              </section>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default CreditNoteCash;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { BsWindowStack } from "react-icons/bs";
import { FaSave } from "react-icons/fa";
import { SiBookstack } from "react-icons/si";
import { GrDocument } from "react-icons/gr";
import { GiCrossMark } from "react-icons/gi";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { RiFileExcel2Line } from "react-icons/ri";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
// import "./";
import Loading from "../Components/Loading";
import axios from "axios";
import { AiFillPrinter } from "react-icons/ai";
import AllPageNavbar from "../Components/AllPageNavbar";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { poToGrn } from "../Redux/Action/poToGrn";
// import Loading from "../Components/Loading";
import { useLocation } from "react-router-dom";
// import { resetPoToGrn } from "../../src/Redux/actions/poToGrn";
import { resetPoToGrn } from "../Redux/Action/poToGrn";
import LoadingSpinner from "../Components/Loader/UploadLoader";

function POProcessing() {
  const [isloading, setisloading] = useState(false);
  const authToken = window.sessionStorage.getItem("auth-token");
  const sessiontoken = window.sessionStorage.getItem("session-token");
  const BASEURL = process.env.REACT_APP_BASEURL;
  const BASEURL_REPORTS = process.env.REACT_APP_BASEURL_REPORTS;
  const userState = useSelector((state) => state.changeTheState.user);
  const divsionInitial = useSelector((state) => state?.changeDivision);
  const LoggedInUserName = userState?.emailId.split("@")[0];
  const [ProcessedBy, setProcessedBy] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [getPOdetails, setgetPOdetails] = useState([]);
  const [message, setMessage] = useState("");
  const [HeaderInfo, setHeaderInfo] = useState([]);
  const [DetailsOfPo, setDetailsOfPo] = useState([]);
  const [CurrentPoDetails, SetCurrentPoDetails] = useState([]);
  const poData = useSelector((state) => state.poToGrnReducer);
  const [getPOno, setgetPOno] = useState("");
  const [currentPonum, SetcurrentPonum] = useState("");
  const [lastPonumber, setLastponumber] = useState("");
  const [getSupplierName, setgetSupplierName] = useState([]);
  const [StartPoNum, SetStartPoNum] = useState("");
  const [divission, setDivission] = useState([]);
  const [Vehicles, setVehicles] = useState([]);
  const [Paymenymode, SetPaymenymode] = useState([]);
  const [Vat, setVat] = useState("");
  const [SelectedSupplierCode, setSelectedSupplierCode] = useState("");
  const [currency, setCurrency] = useState("");
  const [selectedSupplier, setSelectedSupplier] = useState("");
  const [selectedPODate, setSelectedPODate] = useState("");
  const [selectedPayMode, setSelectedPayMode] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [selectedDivision, setSelectedDivision] = useState(divsionInitial);
  const [Notes, setNotes] = useState("");
  const [search, setSearch] = useState("");
  const [cashsalepopup, setcashsalepopup] = useState(false);
  const [itempopup, setItempopup] = useState(false);
  const [getItemDetails, setgetItemDetails] = useState([]);
  const [amountIncVat, setAmountIncVat] = useState(0);
  const [vatAmount, setVatAmount] = useState(0);
  const [amountExcVat, setAmountExcVat] = useState(0);
  const [quantity, setQuantity] = useState("");
  const [latestCostPrice, setLatestCostPrice] = useState("");
  const [vatableValue, setVatableValue] = useState("");

  const [addedItems, setAddedItems] = useState([]);
  const [AppovedPO, setAppovedPO] = useState("");
  const [SavedPoNo, setSavedPoNo] = useState("");

  const handleInputChangeitemrate = (event) => {
    setLatestCostPrice(event.target.value);
  };
  const currentDate = new Date();
  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  const localStartOfMonth = new Date(
    startOfMonth.getTime() - startOfMonth.getTimezoneOffset() * 60000
  );
  const [startdate, setstartdate] = useState(localStartOfMonth.toISOString());
  const [enddate, setEnddate] = useState(currentDate.toISOString());
  const [currentDates, setCurrentDates] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [currentTime, setCurrentTime] = useState(
    new Date().toTimeString().slice(0, 8)
  );

  const [pdfData, setPdfData] = useState(null);
  const [LoadingScreenBlock, setLoadingScreenBlock] = useState(false);
  const [hist, setHist] = useState(false);
  const [reportName, setReportName] = useState("");
  const [reportPath, setReportPath] = useState("");

  //**curreny date and current time */
  useEffect(() => {
    setCurrentDates(new Date().toISOString().slice(0, 10));
    setCurrentTime(new Date().toTimeString().slice(0, 8));
    getSuppliername();
    getReportName();
  }, []);

  // ****FUNCTION TO FETCH INTIAL VALUE
  function NewPofunction() {
    dispatch({ type: "RESET_POTOGRN" });
    window.location.reload();
    setSelectedPODate("");
  }

  function getReportName() {
    axios
      .get(`${BASEURL_REPORTS}/getreportnames?DOCUMENTNAME=Purchase Order`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setReportName(response?.data[1]?.CRNAME);
        setReportPath(response?.data[1]?.REPORTPATH);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }

  function getSuppliername() {
    setisloading(true);
    axios
      .get(`${BASEURL}/getNewPoNo`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setgetPOno(
          poData?.poHeader?.PONO
            ? poData.poHeader.PONO
            : response.data[0].NEXTPONO
        );
        SetcurrentPonum(response.data[0].NEXTPONO);
        setLastponumber(response.data[0].NEXTPONO);
        setisloading(false);
      });

    axios
      .get(`${BASEURL}/getPoHeaders`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setgetPOdetails(response.data);
        SetStartPoNum(response.data[0].PONO);
        setisloading(false);
      });

    axios
      .get(`${BASEURL}/getListOfSupplier`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setgetSupplierName(response.data);
        setisloading(false);
      });

    axios
      .get(`${BASEURL}/getListOfPayMode`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        // setgetSupplierName(response.data);
        setisloading(false);
        SetPaymenymode(response.data);
      });
    // axios
    //   .get(`${BASEURL}/getListOfVehicles`, {
    //     headers: {
    //       "auth-token": authToken,
    //     },
    //   })
    //   .then((response) => {
    //     setVehicles(response.data);
    //     setisloading(false);
    //   });
    axios
      .get(`${BASEURL}/getDivisionsZX`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setDivission(response.data);
        setisloading(false);
      });
    axios
      .get(`${BASEURL}/getVat`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })
      .then((response) => {
        setVat(response.data[0]);
        setisloading(false);
      });
    axios
      .get(`${BASEURL}/getListOfInventory?DIVISION=${divsionInitial}`, {
        headers: {
          "auth-token": authToken,
          "session-token": sessiontoken,
        },
      })

      .then((response) => {
        setgetItemDetails(response.data);
        setisloading(false);
      })
      .catch((error) => {
        console.error("Error fetching list of inventory:", error);
      });
  }
  // *** Redux from GRN **//
  useEffect(() => {
    function processPoData(poData) {
      if (poData.poHeader.PONO) {
        setHeaderInfo(poData.poHeader);
        SetCurrentPoDetails(poData.poDetails);
        // setDetailsOfPo(poData.poDetails[0]);
        setgetPOno(poData.poHeader.PONO);
        setSelectedPODate(poData.poHeader.PODATE?.toString().split("T")[0]);
        getPoHeaders(poData.poHeader.PONO);
        axios
          .get(
            `${BASEURL}/getPoHeaders?PONO=${
              poData.poHeader.PONO ? poData.poHeader.PONO : ""
            }`,
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          )
          .then((response) => {
            setAppovedPO(response.data[0].POAPPROVED);
            // SetCurrentPoDetails(response.data);
          })
          .catch((error) => {
            console.error("Error fetching List Of Credit Sale History:", error);
          });
      }
    }

    processPoData(poData);
  }, [poData]);
  // *** Redux from Job card**//
  useEffect(() => {
    function processPoData(poData) {
      if (poData?.poDetails[0]?.POITEMCODE) {
        setHeaderInfo(poData.poHeader);
        setQuantity();
        SetCurrentPoDetails(poData.poDetails);
        setLatestCostPrice(poData.poDetails.POITEMRATE);
        dispatch({ type: "RESET_POTOGRN" });
        // setDetailsOfPo(poData.poDetails[0]);
        // setgetPOno(poData.poHeader.PONO);
        // setSelectedPODate(poData.poHeader.PODATE?.toString().split("T")[0]);
        // axios
        //   .post(
        //     `${BASEURL}/detailsofPOheaders`,
        //     {
        //       PONO: poData.poHeader.PONO,
        //     },
        //     {
        //       headers: {
        //         "auth-token": authToken,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     setAppovedPO(response.data[0].POAPPROVED);
        //     // SetCurrentPoDetails(response.data);
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching List Of Credit Sale History:", error);
        //   });
      }
    }

    processPoData(poData);
  }, [poData]);

  // ***** POSTING NO FOR HEADER AND DETAILS
  function getPoHeaders(newPOno) {
    if (newPOno) {
      axios
        .get(
          `${BASEURL}/getPoHeaders?PONO=${newPOno}`,

          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setHeaderInfo(response.data[0]);
          setSelectedPODate(response.data[0].PODATE?.toString().split("T")[0]);

          setProcessedBy(response.data[0].POCREATEDBY);

          setgetPOno(response.data[0].PONO);

          setSavedPoNo(response.data[0].PONO);
          dispatch({ type: "RESET_POTOGRN" });
          let message = "";

          if (
            response.data[0]?.POCLOSED == 1 &&
            response.data[0]?.POAPPROVED == 1
          ) {
            message = "PO Closed";
          } else if (response.data[0]?.POAPPROVED == 0) {
            message = "PO is Saved, Need to Approve";
          } else if (response.data[0]?.POAPPROVED == 1) {
            message = "PO Approved";
          } else if (response.data[0]?.POAPPROVED == null) {
            message = "PO is Saved, Need to Approve";
          }
          setMessage(message);
        })
        .catch((error) => {
          console.error("Error fetching List Of Credit Sale History:", error);
        });
      axios
        .get(
          `${BASEURL}/getPoChilds?PONO=${newPOno}`,

          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          setDetailsOfPo(response.data);
          SetCurrentPoDetails(response.data);
        })
        .catch((error) => {
          console.error("Error fetching List Of Credit Sale History:", error);
        });
    }
  }

  // **** BUTTON FUNCTIONS
  const handleButtonClick = (action) => {
    let newPOno;
    setHist(true);
    setPdfData(null);
    switch (action) {
      case "first":
        newPOno = StartPoNum;
        break;
      case "decrement":
        newPOno = Number(getPOno) - 1;
        if (newPOno < StartPoNum) {
          alert("Cannot decrement further");
          return;
        }

        break;

      case "increment":
        newPOno = Number(getPOno) + 1;
        if (newPOno >= currentPonum) {
          alert("No Further PO number");
          return;
        }
        break;

      case "latest":
        newPOno = lastPonumber - 1;
        break;
      default:
        break;
    }

    setgetPOno(newPOno);
    getPoHeaders(newPOno);
  };

  // **** Supplier Code ****//
  const handleSupplierChange = (selectedOption) => {
    setSelectedSupplier(selectedOption.value);
    const selectedSupplierObj = getSupplierName.find(
      (supplier) => supplier["VENDNAME"] === selectedOption.value
    );
    const supplierCode = selectedSupplierObj
      ? selectedSupplierObj["VENDORCODE"]
      : "";

    const currency = selectedSupplierObj ? selectedSupplierObj["CURRENCY"] : "";
    setCurrency(currency);
    setSelectedSupplierCode(supplierCode);
    return supplierCode;
  };

  /************  THIS IS FOR FILTERING THE POHEADER  ****/
  const filtHistoricCash = getPOdetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);
    const sDate = item.PODATE;
    const eDate = item.PODATE;

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["PODATE", "SUPPLIER NAME", "PONO"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });
    const dateMatch =
      (!startdate || sDate >= startdate) && (!enddate || eDate <= enddate);

    return textMatch && dateMatch;
  });

  // **** THIS IS FOR FILTERING THE PODETAILS ////
  const filtHistoricCashItem = getItemDetails.filter((item) => {
    const searchData = search.toLowerCase().split(" ").filter(Boolean);

    if (searchData.length === 0 && !startdate && !enddate) {
      return true;
    }

    const textMatch = searchData.every((term) => {
      return ["ITEMCODE", "ITEMDESCRIPTION"].some((key) => {
        const columnValue = String(item[key]).toLowerCase();
        return columnValue.includes(term);
      });
    });

    return textMatch;
  });

  // *** SELECT THE VALUE ////
  const handleInputChange = (e) => {
    setSelectedPODate(e.target.value);
  };
  const handlePayModeChange = (selectedOption) => {
    setSelectedPayMode(selectedOption.value);
  };

  const handleVehicleChange = (selectedOption) => {
    setSelectedVehicle(selectedOption.value);
  };

  const handleDivisionChange = (selectedOption) => {
    setSelectedDivision(selectedOption.value);
  };

  // *****ONCLICK ROW OF POPUP HEADER PO

  const handleCreditSaleClick = (row) => {
    setHist(true);
    setPdfData(null);
    // setShowTable(true);
    // setCashSaleHistoric(false);
    setHeaderInfo(row);
    // setgetPOno(row.PONO);
    getPoHeaders(row.PONO);
    setcashsalepopup(false);
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (newQuantity < 0) {
      alert("Quantity Shouldn't be Negative");
      return;
    }
    setQuantity(newQuantity);
  };
  // *** Select the item from Inventatory///
  const handleItemmaptoinput = (row) => {
    setDetailsOfPo(row);

    setItempopup(false);

    setLatestCostPrice?.(row["COSTPRICE"] ?? 0);
    setQuantity("");
    if (
      row &&
      row["ITEM VATABLE"] !== undefined &&
      row["ITEM VATABLE"] !== null
    ) {
      setVatableValue(row["ITEM VATABLE"] == "NO" ? "0" : "1"); // Convert to string "0" or "1"
    } else {
      setVatableValue("Select");
    }
  };

  // **** Calculate Amount //
  const [totalAmountExclusiveVat, setTotalAmountExclusiveVat] = useState(0);
  const [totalAmountVatable, setTotalAmountVatable] = useState(0);
  const [totalIncVatAmount, setTotalIncVatAmount] = useState(0);
  const [ItemRateValue, setItemRateValue] = useState(0);
  useEffect(() => {
    const exclusiveVatTotal = CurrentPoDetails.reduce(
      (total, item) => total + item.POITEMRATE * item.POITEMQTY,
      0
    );
    setTotalAmountExclusiveVat(exclusiveVatTotal);

    const VatTotal = CurrentPoDetails.reduce(
      (total, item) => total + (item.POVAT || 0),
      0
    );
    setTotalAmountVatable(VatTotal);
    const iNclusiveVatTotal = CurrentPoDetails.reduce(
      (total, item) => total + (item.POITEMAMOUNT || 0),
      0
    );
    setTotalIncVatAmount(iNclusiveVatTotal);
    const itemperrate = CurrentPoDetails.reduce(
      (total, item) => total + (item.POITEMRATE / item.POITEMQTY || 0),
      0
    );
    setItemRateValue(itemperrate);
  }, [CurrentPoDetails]);

  // **** Amount calculation from item  **/
  useEffect(() => {
    if (Vat && Vat.VATPERC) {
      let tax = Vat.VATPERC;
      const calculatedAmountIncVat =
        vatableValue == 1 && latestCostPrice > 0
          ? (quantity * latestCostPrice * (100 + tax)) / 100
          : vatableValue == 1 && DetailsOfPo?.["COSTPRICE"] > 0
          ? (quantity * DetailsOfPo?.["COSTPRICE"] * (100 + tax)) / 100
          : vatableValue == 0 && latestCostPrice > 0
          ? quantity * latestCostPrice
          : vatableValue == 0 && DetailsOfPo?.["COSTPRICE"] > 0
          ? quantity * DetailsOfPo?.["COSTPRICE"]
          : 0;

      const calculatedVatAmount =
        vatableValue == 1 && latestCostPrice > 0
          ? quantity * latestCostPrice * (tax / 100)
          : vatableValue == 1 && DetailsOfPo?.["COSTPRICE"] > 0
          ? quantity * DetailsOfPo?.["COSTPRICE"] * (tax / 100)
          : 0;

      const latestCostPriceNumber = parseFloat(latestCostPrice);
      const selectedItemCostPriceNumber = parseFloat(
        DetailsOfPo?.["COSTPRICE"]
      );

      const calculatedAmountExcVat =
        latestCostPriceNumber > 0 ? latestCostPriceNumber * quantity : 0;

      setAmountIncVat(calculatedAmountIncVat);
      setVatAmount(calculatedVatAmount);
      setAmountExcVat(calculatedAmountExcVat);
    }
  }, [quantity, DetailsOfPo, latestCostPrice, vatableValue, Vat]);

  const handleAddToGrid = () => {
    if (DetailsOfPo && DetailsOfPo["ITEMCODE"]) {
      if (!quantity) {
        alert("Enter the quantity");
        return;
      }
      if (!vatableValue) {
        alert("Select Vatable");
        return;
      }

      const editedItemIndex = CurrentPoDetails.findIndex(
        (item) => item.POITEMCODE === DetailsOfPo["ITEMCODE"]
      );

      if (editedItemIndex !== -1) {
        const updatedItem = {
          ...CurrentPoDetails[editedItemIndex],
          POITEMQTY: quantity,
          POVATABLE: vatableValue,
          POITEMAMOUNTEXCVAT: amountExcVat,
          POVAT: vatAmount,
          POITEMAMOUNT: amountIncVat,
          POITEMRATE: latestCostPrice,
        };

        const updatedItems = [...CurrentPoDetails];
        updatedItems[editedItemIndex] = updatedItem;

        SetCurrentPoDetails(updatedItems);
        // Clear input fields and state variables
        setDetailsOfPo({
          ITEMCODE: "",
          ITEMDESCRIPTION: "",
          "ITEM VATABLE": "",
          COSTPRICE: "",
          UOM: "",
          "ITEM SELLING PRICE": "",
          "ITEM SELLING CURRENCY": "",
        });
        setQuantity("");
        setLatestCostPrice(0);
        setVatableValue("");
      } else {
        // If the item does not exist in the list, add it as a new row
        const newItem = {
          POITEMCODE: DetailsOfPo["ITEMCODE"],
          POITEMDESCRIPTION: DetailsOfPo["ITEMDESCRIPTION"],
          POITEMUOM: DetailsOfPo["UOM"],
          POVATABLE: vatableValue,
          POITEMQTY: quantity,
          POCURRENCY: currency ? currency : HeaderInfo["POCURRENCY"],
          POITEMRATE: latestCostPrice,
          POITEMAMOUNTEXCVAT: amountExcVat,
          POVAT: vatAmount,
          POITEMAMOUNT: amountIncVat,
        };

        SetCurrentPoDetails([...CurrentPoDetails, newItem]);
        // Clear input fields and state variables
        setDetailsOfPo({
          ITEMCODE: "",
          ITEMDESCRIPTION: "",
          "ITEM VATABLE": "",
          COSTPRICE: "",
          UOM: "",
          "ITEM SELLING PRICE": "",
          "ITEM SELLING CURRENCY": "",
        });
        setQuantity("");
        setLatestCostPrice(0);
        setVatableValue("");
      }
    } else {
      alert("Select the item code");
    }
  };
  // **** Remove From Edit //
  const handleRemoveFromGrid = () => {
    if (CurrentPoDetails.length > 0) {
      const updatedCurrentPoDetails = [...CurrentPoDetails];
      updatedCurrentPoDetails.pop();
      SetCurrentPoDetails(updatedCurrentPoDetails);
    } else {
      console.log("No items to remove");
    }
  };

  // *** Edit row for Item Rate ///
  const handleEditRow = (index) => {
    const editedRow = CurrentPoDetails[index];
    setDetailsOfPo({
      ITEMCODE: editedRow.POITEMCODE,
      ITEMDESCRIPTION: editedRow.POITEMDESCRIPTION,
      "ITEM VATABLE": editedRow.POVATABLE,
      COSTPRICE: editedRow.POITEMRATE,
      UOM: editedRow.POITEMUOM,
      "ITEM SELLING PRICE": editedRow.POITEMRATE,
    });
    setAmountExcVat(editedRow.POITEMAMOUNTEXCVAT);
    if (
      editedRow &&
      editedRow.POVATABLE !== undefined &&
      editedRow.POVATABLE !== null
    ) {
      setVatableValue(editedRow.POVATABLE == 0 ? "0" : "1"); // Convert to string "0" or "1"
    } else {
      setVatableValue("Select");
    }
    // setVatableValue(editedRow.POVATABLE);
    setQuantity(editedRow.POITEMQTY);
    setVatAmount(editedRow.POVAT);
    setAmountIncVat(editedRow.POITEMAMOUNT);
    setLatestCostPrice(editedRow.POITEMRATE);
    return;
  };

  // *** Save POHEADERS AND PODETAILS
  function Save() {
    const supplierCode = SelectedSupplierCode || HeaderInfo["POSUPPLIERCODE"];
    const supplierCurrency = currency || HeaderInfo["POCURRENCY"];
    if (!supplierCurrency) {
      alert("Please provide a Supplier Curreny");
      return;
    }
    if (!supplierCode) {
      alert("Please provide a supplier code.");
      return;
    }
    const noted = Notes || HeaderInfo["PONOTES"];
    if (!noted) {
      alert("Please Provide note");
      return;
    }
    const pay = selectedPayMode || HeaderInfo["POMODEOFPAY"];

    if (!pay) {
      alert("Please Select Pay Mode");
      return;
    }

    const vechicleselect = selectedVehicle || HeaderInfo["VEHICLE"];

    // if (!vechicleselect) {
    //   alert("Please Select Vehicle");
    //   return;
    // }
    if (!selectedDivision) {
      alert("Select the Division");
      return;
    }

    const Dates =
      currentDates.slice(0, 4) +
      "/" +
      currentDates.slice(5, 7) +
      "/" +
      currentDates.slice(8, 10);
    const Podate =
      selectedPODate.slice(0, 4) +
      "/" +
      selectedPODate.slice(5, 7) +
      "/" +
      selectedPODate.slice(8, 10);

    if (Podate == "//") {
      alert("Please Select date");
      return;
    }
    if (!totalAmountExclusiveVat) {
      alert("Select Item");
      return;
    }
    setisloading(true);
    axios
      .post(
        `${BASEURL}/insertPoHeader`,
        {
          PONO: getPOno,
          POITEMSSERVICE: 1,
          SUPPLIERCODE: SelectedSupplierCode
            ? SelectedSupplierCode
            : HeaderInfo["POSUPPLIERCODE"],
          PODATE: Podate,
          POVEHICLE: "",
          DIVISION: selectedDivision,
          PONOTES: Notes ? Notes : HeaderInfo["PONOTES"],
          CREATEDBY: LoggedInUserName,
          CREATEDDATE: Dates,
          CREATEDTIME: currentTime,
          AMOUNTEXCVAT: totalAmountExclusiveVat,
          VATAMOUNT: totalAmountVatable,

          AMTOUNTINCLUSIVEVAT: totalIncVatAmount,
          CURRENCYCODE: currency ? currency : HeaderInfo["POCURRENCY"],
          MODEOFPAY: selectedPayMode
            ? selectedPayMode
            : HeaderInfo["POMODEOFPAY"],
          SUPPLIERNAME: selectedSupplier
            ? selectedSupplier
            : HeaderInfo["SUPPLIER NAME"],
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
        }
      )
      .then((headerResponse) => {
        const headerPromises = CurrentPoDetails.map((item, index) => {
          return axios.post(
            `${BASEURL}/insertPoItems`,
            {
              PONO: getPOno,
              PODATE: Podate,
              SERIALNO: index + 1,
              ITEMCODE: item.POITEMCODE,
              ITEMDESCRIPTION: item.POITEMDESCRIPTION,
              UOM: item.POITEMUOM,
              ITEMQTY: item.POITEMQTY,
              ITEMRATE: item.POITEMRATE,
              ITEMVAT: item.POVAT,
              ITEMCURRENCY: item.POCURRENCY,
              ITEMAMOUNT: item.POITEMAMOUNT,
              DIVISION: selectedDivision,
              CREATEDBY: LoggedInUserName,
              CREATEDDATE: Dates,
            },
            {
              headers: {
                "auth-token": authToken,
                "session-token": sessiontoken,
              },
            }
          );
        });

        return Promise.all(headerPromises);
      })
      .then((detailResponses) => {
        // setSavedDetails(detailResponses);
        getPoHeaders(getPOno);
        alert(detailResponses[0].data.message);

        setisloading(false);

        setSelectedPODate("");
        // setMessage("PO is Saved, Need to Approve");
      })
      .catch((error) => {
        console.error(error);
        setisloading(false);
      });
  }

  // * APPROVE FUNCTION *//
  function Ammend(actionType) {
    let valueForPOCANCELAPPROVEAMMEND;

    if (
      (HeaderInfo?.POAPPROVED == 1 || AppovedPO == 1) &&
      actionType == "CLOSE"
    ) {
      valueForPOCANCELAPPROVEAMMEND = "CLOSE";
    }

    if (
      (HeaderInfo?.POAPPROVED == 0 ||
        AppovedPO == 0 ||
        HeaderInfo?.POAPPROVED == null ||
        AppovedPO == null) &&
      actionType == "APPROVE"
    ) {
      valueForPOCANCELAPPROVEAMMEND = "APPROVE";
    }

    if (
      (HeaderInfo?.POAPPROVED == 1 || AppovedPO == 1) &&
      actionType == "AMMEND"
    ) {
      valueForPOCANCELAPPROVEAMMEND = "AMMEND";
    }

    const Dates =
      currentDates.slice(0, 4) +
      "/" +
      currentDates.slice(5, 7) +
      "/" +
      currentDates.slice(8, 10);
    if (valueForPOCANCELAPPROVEAMMEND?.length > 0 && SavedPoNo.length > 0) {
      axios
        .post(
          `${BASEURL}/approveCancelAmendPO`,
          {
            PONO: getPOno,
            POCANCELAPPROVEAMMEND: valueForPOCANCELAPPROVEAMMEND,
            DATE: Dates,
            TIME: currentTime,
            PROCESSEDBY: LoggedInUserName,
          },
          {
            headers: {
              "auth-token": authToken,
              "session-token": sessiontoken,
            },
          }
        )
        .then((response) => {
          getPoHeaders(getPOno);
          alert(response.data.message);
          // setMessage("PO is Approved");
          // window.location.reload();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      if (SavedPoNo.length == 0) {
        alert("Save the PO");
        setisloading(false);
      } else {
        alert("Approve the PO");
        setisloading(false);
      }
    }
  }

  const poDetailsArray = CurrentPoDetails.map((item, index) => ({
    ItemCode: item.POITEMCODE,
    ItemName: item.POITEMDESCRIPTION,
    Quantity: item.POITEMQTY,
    Rate: ItemRateValue + (item.POITEMAMOUNT - ItemRateValue),
    TotalAmount: item.POITEMAMOUNT * item.POITEMQTY,
    UOM: item.POITEMUOM,
    Vatable: item.POVATABLE,
    Rateexc: ItemRateValue,
    FixedQty: item.POITEMQTY,
    FixedTotal: item.POITEMAMOUNT * item.POITEMQTY,
    PoQty: item.POITEMQTY,
  }));
  const customStyless = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "300px",
      maxWidth: "300px",
      marginTop: "0px",
      minHeight: "initial",
      height: "32px",
      border: "1px solid",
      paddingBottom: "5px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };
  const customStylessforpay = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      minWidth: "210px",
      maxWidth: "210px",
      marginTop: "0px",
      minHeight: "initial",
      height: "32px",
      border: "1px solid",
      paddingBottom: "5px",
      paddingTop: "0px",
      marginLeft: "0px",
      backgroundColor: "white",
      color: "black",
      textTransform: "uppercase",
      "@media (max-width: 720px)": {
        minWidth: "181px",
      },
    }),
  };

  // Crystal Reports
  function generateReport() {
    setPdfData(null);

    if (!getPOno) {
      alert("Please select a fuel Voucher No ");
      return;
    }
    setLoadingScreenBlock(true);

    const updatedReportPath = `${reportPath?.replace(/\\\\/g, "\\")}`;
    console.log(updatedReportPath);
    axios
      .post(
        `${BASEURL_REPORTS}/getReports`,
        {
          REPORTNAME: reportName,
          DOCNO: Number(getPOno),
          REPORTPATH: `${reportPath?.replace(/\\\\/g, "\\")}`,
        },
        {
          headers: {
            "auth-token": authToken,
            "session-token": sessiontoken,
          },
          responseType: "blob",
        }
      )
      .then((res) => {
        console.log(res);
        const file = new Blob([res.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        setPdfData(fileURL);
        setLoadingScreenBlock(false);
      })
      .catch((err) => {
        console.log("Error generating report:", err);
        setLoadingScreenBlock(false);
      });
  }

  const handleDownload = () => {
    const a = document.createElement("a");
    a.href = pdfData;
    a.download = "generated_report.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (isloading) {
    return <Loading />;
  }
  return (
    <div className="text-ls">
      <div className="tohide__Print">
        <AllPageNavbar />{" "}
        <header className="header__of__main flex justify-between items-end px-3">
          <h1 className="header__of__page"> Purchase Order</h1>
          <main className="flex justify-end p-2 text-right gap-[19px]">
            <p className="font-bold">
              DIVISION :{" "}
              <span className="text-red-600">{selectedDivision}</span>
            </p>
            <p className="font-bold">
              PROCESSED BY :{" "}
              <span className="text-red-600">
                {" "}
                {ProcessedBy ? ProcessedBy : LoggedInUserName}
              </span>
            </p>
          </main>{" "}
        </header>
        <section className="block justify-between lg:lg:flex ">
          <div className="flex felx-row mt-2 lg:ml-[30px] ml-[10px] mb-2">
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-2    mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("first");
              }}
            >
              {"<<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-2  mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("decrement");
              }}
            >
              {"<"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-2   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("increment");
              }}
            >
              {">"}
            </button>
            <button
              className="  border border-black bg-[#002d62] text-white rounded-md px-2   mr-1 bg-[#002e62dd] focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                handleButtonClick("latest");
              }}
            >
              {">>"}
            </button>
          </div>

          <div className="lg:flex  justify-between gap-2 mr-2">
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => NewPofunction()}
            >
              New PO
            </button>{" "}
            <button
              onClick={Save}
              className="bg-blue-900 text-white   h-7 text-lg  rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring"
            >
              Save{" "}
            </button>
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => Ammend("APPROVE")}
            >
              Approve
            </button>{" "}
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => Ammend("AMMEND")}
            >
              Amend PO
            </button>
            <button
              onClick={() => {
                if (HeaderInfo.POAPPROVED || AppovedPO == 1) {
                  axios
                    .get(
                      `${BASEURL}/getPendingPurchaseorderDetails?PONO=${getPOno}`,
                      {
                        headers: {
                          "auth-token": authToken,
                          "session-token": sessiontoken,
                        },
                      }
                    )
                    .then((response) => {
                      const updatedArray = response.data?.map((item) => {
                        return {
                          PoQty: Number(item.POQTY),
                          ItemCode: item.ITEMCODE,
                          ItemName: item.ITEMNAME,
                          FixedQty: Number(item.POQTY) - Number(item.GRNQTY),
                          Quantity: Number(item.POQTY) - Number(item.GRNQTY),
                          Rate:
                            Number(item.ITEMRATE) +
                            Number(item.VATAMOUNT) / Number(item.POQTY),
                          TotalAmount:
                            (Number(item.ITEMRATE) +
                              Number(item.VATAMOUNT) / Number(item.POQTY)) *
                            (Number(item.POQTY) - Number(item.GRNQTY)),
                          UOM: item.UOM,
                          Vatable: item.VATABLE,
                          Rateexc: Number(item.ITEMRATE),
                        };
                      });
                      dispatch(
                        poToGrn({
                          poHeader: {
                            PONO: getPOno,
                            SUPPLIERCODE: HeaderInfo["POSUPPLIERCODE"],
                            SUPPLIERNAME: HeaderInfo["SUPPLIER NAME"],
                            SUPPLIERCURRENCY: HeaderInfo["POCURRENCY"],
                            DIVISION: HeaderInfo["DIVISION"],
                          },
                          poDetails: updatedArray,
                        })
                      );
                      navigate("/GrnProcessing");
                    });
                } else {
                  alert("PO is Not Approved!");
                }
              }}
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
            >
              Process Grn
            </button>
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => Ammend("CLOSE")}
            >
              Close PO
            </button>
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
            <button
              className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
              onClick={() => {
                if (hist === false) {
                  alert("Kindly Save the Purchase order first");
                } else {
                  generateReport();
                }
              }}
            >
              Generate Report
            </button>
          </div>
        </section>
        {message && (
          <section className="lg:flex bg-slate-300   shadow gap-[4px] block justify-end">
            {" "}
            STATUS :<span className="text-red-600">{message}</span>
          </section>
        )}
        <section className="lg:flex bg-slate-100 mx-1 p-2  shadow gap-[4px] block justify-between">
          <p className="block lg:flex items-center ">
            <p className="w-auto">PO NO </p>
            <input
              className="w-16 h-7 border border-black  p-2 rounded-md focus:outline-none focus:border-blue-500 ml-1"
              type="number "
              value={getPOno ? getPOno : ""}
            ></input>
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  h-7 text-white rounded-md p-2 ml-2 mr-2 focus:outline-none focus:ring focus:border-blue-300"
              onClick={() => {
                setcashsalepopup(true);
              }}
            >
              ...
            </button>
          </p>

          {cashsalepopup && (
            <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
              <div className="flex flex-row mt-2">
                <p className="text-red-600 ml-2">Search:</p>
                <input
                  className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  type="text"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                ></input>
                <p className="text-red-600 ml-2">Start Date:</p>
                <input
                  type="date"
                  className="w-36  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setstartdate(e.target.value);
                  }}
                  value={startdate.split("T")[0]}
                ></input>
                <p className="text-red-600 ml-2">End Date:</p>
                <input
                  type="date"
                  className="w-36  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                  onChange={(e) => {
                    setEnddate(e.target.value);
                  }}
                  value={enddate.split("T")[0]}
                ></input>
              </div>

              <button
                className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                onClick={() => setcashsalepopup(false)}
              >
                Close
              </button>
              <aside className="table__pos__app ">
                <table class="custom-table">
                  <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                    <tr>
                      <td className="text-right ">PO NO</td>

                      <td className="text-left">PO DATE</td>
                      <td className="text-left">SUPPLIER NAME</td>
                      <td className="text-left">STATUS</td>
                      <td className="text-left">APPROVED BY</td>
                    </tr>
                  </thead>
                  <tbody>
                    {filtHistoricCash.map((row, index) => (
                      <tr
                        key={row.PONO}
                        onClick={() => {
                          handleCreditSaleClick(row);
                        }}
                        className={
                          index < filtHistoricCash.length
                            ? "border-b cursor-pointer table-row"
                            : ""
                        }
                      >
                        <td className="text-right pr-6">
                          {row.PONO ? row.PONO : ""}
                        </td>
                        <td>
                          {row.PODATE
                            ? moment(row.PODATE.split("T")[0]).format(
                                "DD/MM/YYYY"
                              )
                            : ""}
                        </td>
                        <td>
                          {row["SUPPLIER NAME"] ? row["SUPPLIER NAME"] : ""}
                        </td>
                        <td>{row["POAPPROVED"] == 1 ? "YES" : "NO"}</td>
                        <td>
                          {row["POAPPROVEDBY"] ? row["POAPPROVEDBY"] : ""}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </aside>
            </div>
          )}
          <p className="block lg:flex items-center ">
            <p className="w-auto">Supplier </p>
            <Select
              styles={customStyless}
              className="ml-2"
              value={{
                label:
                  selectedSupplier.length > 0
                    ? selectedSupplier
                    : HeaderInfo && HeaderInfo["SUPPLIER NAME"]
                    ? HeaderInfo["SUPPLIER NAME"]
                    : "",

                value:
                  selectedSupplier.length > 0
                    ? selectedSupplier
                    : HeaderInfo && HeaderInfo["SUPPLIER NAME"]
                    ? HeaderInfo["SUPPLIER NAME"]
                    : "",
              }}
              onChange={handleSupplierChange}
              options={getSupplierName.map((item) => ({
                value: item["VENDNAME"],
                label: item["VENDNAME"],
              }))}
            />
          </p>
          <p className="block lg:flex items-center">
            <p className="">PO Date </p>
            <input
              type="date"
              className="w-[130px] rounded-md ml-1"
              name="poDate"
              value={selectedPODate}
              onChange={handleInputChange}
            />
          </p>
          <p className="block lg:flex items-center">
            <p className="w-auto">Pay Mode</p>
            <Select
              styles={customStyless}
              className="ml-1"
              value={{
                value:
                  selectedPayMode.length > 0
                    ? selectedPayMode
                    : HeaderInfo?.["POMODEOFPAY"],
                label:
                  selectedPayMode.length > 0
                    ? selectedPayMode
                    : HeaderInfo?.["POMODEOFPAY"],
              }}
              onChange={handlePayModeChange}
              options={Paymenymode.map((item) => ({
                value: item["PAYMENTMODE"],
                label: item["PAYMENTMODE"],
              }))}
            />
          </p>
        </section>
        <section className="lg:flex bg-slate-100 mx-1 p-2 shadow mb-2 gap-[4px] block">
          {/* <p className="block lg:flex items-center">
            <p className="w-auto">Division</p>
            <Select
              styles={customStylessforpay}
              className="ml-1"
              value={{
                label:
                  selectedDivision.length > 0
                    ? selectedDivision
                    : HeaderInfo && HeaderInfo["DIVISION"]
                    ? HeaderInfo["DIVISION"]
                    : "",
                value:
                  selectedDivision.length > 0
                    ? selectedDivision
                    : HeaderInfo && HeaderInfo["DIVISION"]
                    ? HeaderInfo["DIVISION"]
                    : "",
              }}
              onChange={handleDivisionChange}
              options={divission.map((item) => ({
                value: item.DIVISION,
                label: item.DIVISION,
              }))}
            />
          </p> */}
          {/* <p className="block lg:flex items-center">
            <p className="w-auto">Vehicle</p>
            <Select
              styles={customStylessforpay}
              className="ml-1"
              value={{
                label:
                  selectedVehicle.length > 0
                    ? selectedVehicle
                    : HeaderInfo && HeaderInfo["VEHICLE"]
                    ? HeaderInfo["VEHICLE"]
                    : "",
                value:
                  selectedVehicle.length > 0
                    ? selectedVehicle
                    : HeaderInfo && HeaderInfo["VEHICLE"]
                    ? HeaderInfo["VEHICLE"]
                    : "",
              }}
              //   onChange={handleVehicleChange}
              options={Vehicles.map((item) => ({
                value: item["VEHICLE REG NO"],
                label: item["VEHICLE REG NO"],
              }))}
            />
          </p> */}
          <p className="block lg:flex items-center">
            <p className="lg:">PO Notes </p>
            <textarea
              className="max-w-[500px] min-w-[500px] lg:mx-1"
              value={Notes.length > 0 ? Notes : HeaderInfo?.PONOTES ?? ""}
              onChange={(e) => setNotes(e.target.value)}
            ></textarea>
          </p>
        </section>
        <section className="block lg:block">
          <div className="lg:flex block mt-2 ml-2 gap-4 ">
            <p className="flex lg:flex ">
              <p className="block lg:flex items-center  ">
                <p className="text-red-600 mr-2 w-[120px] lg:w-[auto]">
                  Item code
                </p>

                <input
                  className="w-28  h-7 border border-black  p-2 rounded-md focus:outline-none focus:border-blue-500"
                  value={
                    DetailsOfPo?.["ITEMCODE"] ? DetailsOfPo?.["ITEMCODE"] : ""
                  }
                ></input>
              </p>
              <p>
                <button
                  className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-2   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => {
                    setItempopup(true);
                  }}
                >
                  Search
                </button>
              </p>
            </p>
            {itempopup && (
              <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 bg-white border border-black h-[500px] w-[900px] p-1 overflow-y-auto">
                <div className="flex flex-row mt-2">
                  <p className="text-red-600 ml-2">Search:</p>
                  <input
                    className="w-64  h-7 border border-black ml-2 p-2 rounded-md focus:outline-none focus:border-blue-500"
                    type="text"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  ></input>
                </div>

                <button
                  className="absolute top-2 right-2 flex items-center justify-center border border-black bg-[#002d62] text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
                  onClick={() => setItempopup(false)}
                >
                  Close
                </button>
                <aside className="table__pos__app ">
                  <table class="custom-table">
                    <thead class="header-cell sticky top-0 w-98 z-0 text-sm">
                      <tr>
                        <td className="text-right ">ITEM CODE</td>
                        <td className="text-left">ITEM DESCRIPTION</td>
                        <td className="text-right ">QUANTITY IN STOCK</td>
                        <td className="text-right ">UOM</td>
                        <td>VATABLE</td>
                        <td className="text-right ">PRICE</td>
                      </tr>
                    </thead>
                    <tbody>
                      {filtHistoricCashItem.map((row, index) => (
                        <tr
                          key={row.PONO}
                          onClick={() => {
                            handleItemmaptoinput(row);
                          }}
                          className={
                            index < filtHistoricCashItem.length
                              ? "border-b cursor-pointer table-row"
                              : ""
                          }
                        >
                          <td className="text-right pr-6">
                            {row["ITEMCODE"] ? row["ITEMCODE"] : ""}
                          </td>
                          <td>
                            {row["ITEMDESCRIPTION"]
                              ? row["ITEMDESCRIPTION"]
                              : ""}
                          </td>
                          <td className="text-right pr-6">
                            {row["QUANTITY"] ? Math.abs(row["QUANTITY"]) : ""}
                          </td>
                          <td>{row["UOM"] ? row["UOM"] : ""}</td>
                          <td>
                            {row["ITEM VATABLE"] ? row["ITEM VATABLE"] : ""}
                          </td>
                          <td className="text-right pr-6">
                            {row["COSTPRICE"]
                              ? row["COSTPRICE"].toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0.00"}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </aside>
              </div>
            )}
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1">Item Name</p>
              <input
                className=" w-[280px]  h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={DetailsOfPo?.["ITEMDESCRIPTION"]}
              ></input>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1 w-[56px] text-right"> Quantity</p>
              <input
                type="number"
                className="w-36  h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={Math.abs(quantity) ? Math.abs(quantity) : ""}
                onChange={handleQuantityChange}
              ></input>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1  text-right">UOM</p>
              <input
                className="w-[100px]   h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={DetailsOfPo?.["UOM"]}
              ></input>
            </p>
          </div>
          <div className="lg:flex items-center  block flex-row mt-2 ml-2 gap-2">
            <p className="block lg:flex items-center  ">
              <p className="text-red-600 mr-1 ">Item Rate</p>
              <input
                className="w-[130px]  h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={latestCostPrice}
                onChange={handleInputChangeitemrate}
              ></input>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1  text-right">Amount (Exc. Vat)</p>
              <input
                className="w-[100px]   h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={Math.abs(amountExcVat)?.toLocaleString()}
                readOnly
              ></input>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1 text-right">VATABLE</p>
              <select
                className="w-[80px] h-[30px] border border-black ml-1 rounded-md focus:outline-none focus:border-blue-500"
                value={vatableValue}
                onChange={(e) => {
                  setVatableValue(e.target.value);
                }}
              >
                <option value="Select">Select</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1 text-right">Vat Amount</p>
              <input
                className=" w-[100px]  h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={Math.abs(vatAmount)?.toLocaleString()}
                readOnly
              ></input>
            </p>
            <p className="block lg:flex items-center ">
              <p className="text-red-600 mr-1  text-right">Amount (Inc. Vat)</p>
              <input
                className="w-[100px]  h-7 border border-black ml-1 p-2 rounded-md focus:outline-none focus:border-blue-500"
                value={Math.abs(amountIncVat)?.toLocaleString()}
                readOnly
              ></input>
            </p>

            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
              onClick={handleAddToGrid}
            >
              Add
            </button>
            <button
              className="flex items-center justify-center border border-black bg-[#002d62]  text-white rounded-md px-3   ml-2 mr-2    focus:outline-none focus:ring focus:border-blue-300"
              onClick={handleRemoveFromGrid}
            >
              Remove
            </button>
          </div>
        </section>
        <section className="table__pos__app w-[98%]  ">
          <table class="custom-table ">
            <thead class="header-cell">
              <tr>
                <td>SL NO</td>
                <td>ITEM CODE</td>

                <td>ITEM NAME</td>

                <td>QTY</td>
                <td> UOM</td>
                <td>POVATABLE</td>
                <td className="text-right">ITEM RATE</td>

                <td className="text-right">AMOUNT EXC VAT</td>

                <td className="text-right">VAT AMOUNT</td>

                <td className="text-right">AMOUNT INC VAT</td>
              </tr>
            </thead>
            <tbody>
              {CurrentPoDetails &&
                CurrentPoDetails.length > 0 &&
                Array.isArray(CurrentPoDetails) &&
                CurrentPoDetails.map((item, index) => (
                  <tr
                    onClick={() => handleEditRow(index)}
                    key={index}
                    className={
                      index < CurrentPoDetails.length - 1
                        ? "border-b cursor-pointer  table-row"
                        : ""
                    }
                  >
                    <td className="text-right">{index + 1}</td>
                    <td className="text-right">{item.POITEMCODE}</td>
                    <td>{item.POITEMDESCRIPTION}</td>
                    <td className="text-right">
                      {(item.POITEMQTY &&
                        Math.abs(item.POITEMQTY)?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })) ||
                        0}
                    </td>{" "}
                    <td>{item.POITEMUOM}</td>
                    <td>
                      {item.POVATABLE == 1
                        ? "Yes"
                        : item.POVATABLE == 0
                        ? "No"
                        : ""}
                    </td>
                    <td className="text-right">
                      {(item.POITEMRATE &&
                        Math.abs(item.POITEMRATE)?.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })) ||
                        0}
                    </td>
                    <td className="text-right">
                      {" "}
                      {item.POITEMRATE &&
                      item.POITEMQTY &&
                      !isNaN(item.POITEMRATE * item.POITEMQTY)
                        ? (
                            Math.abs(Number(item.POITEMRATE)) *
                            Math.abs(Number(item.POITEMQTY))
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : 0}
                    </td>
                    <td className="text-right">
                      {item.POVAT === 1
                        ? Math.abs(
                            item.POITEMRATE * item.POITEMQTY - item.POITEMAMOUNT
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : Math.abs(item.POVAT).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </td>
                    <td className="text-right">
                      {item.POITEMAMOUNT
                        ? Math.abs(item.POITEMAMOUNT)?.toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )
                        : 0}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </section>
        {/*<div className="flex justify-end items-center lg:mx-[50px]">
          <td colSpan="3">ITEM RATE:</td>
          <td>
            <input
              type="text"
              value={CurrentPoDetails.reduce(
                (total, item) => total + item.POITEMRATE / item.POITEMQTY,
                0
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              readOnly
              style={{ border: "none", color: "red", marginLeft: "6px" }}
            />
          </td>
        </div> */}
        <div className="flex justify-end items-center lg:mx-[50px]">
          <td colSpan="3">TOTAL EXC VAT AMOUNT :</td>
          <td>
            <input
              type="text"
              value={CurrentPoDetails.reduce(
                (total, item) =>
                  Math.abs(total + item.POITEMRATE * item.POITEMQTY),
                0
              )?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              readOnly
              style={{
                border: "none",
                color: "red",
                marginLeft: "6px",
                textAlign: "right",
                width: "130px",
              }}
            />
          </td>
        </div>
        <div className="flex justify-end items-center lg:mx-[50px]">
          <td colSpan="3">TOTAL VAT AMOUNT :</td>
          <td>
            <input
              type="text"
              value={
                CurrentPoDetails.reduce((total, item) => {
                  if (typeof item.POVAT === "number" && !isNaN(item.POVAT)) {
                    if (item.POVAT === 1) {
                      return Math.abs(
                        total +
                          (item.POITEMRATE * item.POITEMQTY - item.POITEMAMOUNT)
                      );
                    } else {
                      return total + Math.abs(item.POVAT);
                    }
                  } else {
                    return total;
                  }
                }, 0)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || "0.00"
              }
              readOnly
              style={{
                border: "none",
                color: "red",
                marginLeft: "6px",
                textAlign: "right",
                width: "130px",
              }}
            />
          </td>
        </div>
        <div className="flex justify-end items-center lg:mx-[50px]">
          <td colSpan="3">TOTAL INC VAT AMOUNT :</td>
          <td>
            <input
              type="text"
              value={
                CurrentPoDetails.reduce((total, item) => {
                  // Check if item.POITEMAMOUNT is a valid number or not null/undefined
                  if (
                    typeof item.POITEMAMOUNT === "number" &&
                    !isNaN(item.POITEMAMOUNT)
                  ) {
                    return Math.abs(total + item.POITEMAMOUNT);
                  } else {
                    return Math.abs(total);
                  }
                }, 0)?.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }) || "0.00"
              }
              readOnly
              style={{
                border: "none",
                color: "red",
                marginLeft: "6px",
                textAlign: "right",
                width: "130px",
              }}
            />
          </td>
        </div>
      </div>

      {/* Report Generation */}
      {LoadingScreenBlock ? (
        <LoadingSpinner />
      ) : (
        <>
          {pdfData ? (
            <div className="w-full justify-center items-center min-h-screen p-4">
              <section className="lg:flex lg:flex-col bg-white p-4 ga  shadow-lg rounded-md w-full min-h-[500px]">
                <div>
                  {/* <div className="flex items-center gap-4"></div> */}

                  <section className="lg:hidden block">
                    {pdfData && (
                      <button
                        className="bg-blue-900 text-white  h-7 text-lg rounded-md px-3 ml-2 mr-2 focus:outline-none focus:ring"
                        onClick={handleDownload}
                      >
                        Download PDF
                      </button>
                    )}
                  </section>

                  <section className="hidden lg:block">
                    {pdfData ? (
                      <div className="mt-4">
                        <h2 className="text-lg font-bold">Generated Report</h2>
                        <iframe
                          src={pdfData}
                          title="Generated Report"
                          width="100%"
                          height="600px"
                        ></iframe>
                      </div>
                    ) : (
                      <p className="text-2xl font-bold my-4 text-center">
                        No report available.
                      </p>
                    )}
                  </section>
                </div>
              </section>
            </div>
          ) : (
            ""
          )}
        </>
      )}

      {/* toshow__Print */}
      <div className="toshow__Print">
        <section className="relative my-[2%] mx-[50px] w-[705px] h-[1000px] bg-slate-50 p-[20px] text-[10px]">
          <main>
            <div className="text-center underline text-xl font-bold color text-blue-900">
              <h1>PURCHASE ORDER</h1>
            </div>
            <p className="flex justify-between mx-[0px] my-[14px] text-[12px]">
              <div className="po__print__Header">
                <p className="firsT___p">PO No : </p>
                <h1>{getPOno ? getPOno : ""}</h1>
                <p className="firsT___p">Payment Mode: </p>
                <h1>
                  {selectedPayMode
                    ? selectedPayMode
                    : HeaderInfo?.["POMODEOFPAY"]}
                </h1>
                {/* <p className="firsT___p">Vehicle : </p>
                <h1>
                  {" "}
                  {selectedVehicle ? selectedVehicle : HeaderInfo?.["VEHICLE"]}
                </h1> */}
                <h1 className="firsT___p">Supplier : </h1>
                <h1>
                  {selectedSupplier
                    ? selectedSupplier
                    : HeaderInfo?.["SUPPLIER NAME"]}
                </h1>
                <h1 className="firsT___p">PO Date : </h1>
                <h1>
                  {selectedPODate?.slice(8, 10) +
                    "/" +
                    selectedPODate?.slice(5, 7) +
                    "/" +
                    selectedPODate?.slice(0, 4)}
                </h1>

                <p className="firsT___p">PO Time : </p>
                <h1>{currentTime}</h1>
                <p className="firsT___p">Currency : </p>
                <h1>{currency ? currency : HeaderInfo?.["POCURRENCY"]}</h1>
              </div>
            </p>
          </main>

          <aside className="">
            <table class="table-print">
              <tr className="px-[4px] py-[4px] text-left">
                <th className="p-2 m-2">PART NO</th>

                <th className="p-2 m-2">PART DESCRIPTION</th>

                <th className="p-2 m-2">QTY</th>
                <th className="p-2 m-2">RATE</th>
                <th className="p-2 m-2">VAT AMOUNT</th>
                <th className="p-2 m-2">AMOUNT EXC VAT</th>
              </tr>
              {CurrentPoDetails &&
                CurrentPoDetails.length > 0 &&
                Array.isArray(CurrentPoDetails) &&
                CurrentPoDetails.map((item, index) => (
                  <tr
                    onClick={() => handleEditRow(index)}
                    key={index}
                    className={
                      index < CurrentPoDetails.length - 1
                        ? "border-b cursor-pointer  table-row"
                        : ""
                    }
                  >
                    <td className="text-right">{item.POITEMCODE}</td>
                    <td>{item.POITEMDESCRIPTION}</td>
                    <td className="text-right">{Math.abs(item.POITEMQTY)}</td>
                    <td className="text-right">{item.POITEMRATE}</td>
                    <td className="text-right">
                      {item.POVAT === 1
                        ? Math.abs(
                            item.POITEMRATE * item.POITEMQTY - item.POITEMAMOUNT
                          ).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                        : Math.abs(item.POVAT).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                    </td>
                    <td className="text-right">
                      {Math.abs(item.POITEMRATE * item.POITEMQTY)}
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={5} className="text-right">
                  Total (Exc VAT):
                </td>
                <td className="text-right">
                  {totalAmountExclusiveVat
                    ? Math.abs(totalAmountExclusiveVat).toLocaleString(
                        undefined,
                        {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }
                      )
                    : 0.0}
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="text-right">
                  Total VAT:
                </td>
                <td className="text-right">
                  <input
                    type="text"
                    value={
                      CurrentPoDetails.reduce((total, item) => {
                        if (
                          typeof item.POVAT === "number" &&
                          !isNaN(item.POVAT)
                        ) {
                          if (item.POVAT === 1) {
                            return Math.abs(
                              total +
                                (item.POITEMRATE * item.POITEMQTY -
                                  item.POITEMAMOUNT)
                            );
                          } else {
                            return total + Math.abs(item.POVAT);
                          }
                        } else {
                          return total;
                        }
                      }, 0)?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }) || "0.00"
                    }
                    readOnly
                    style={{
                      border: "none",
                      color: "red",
                      marginLeft: "6px",
                      textAlign: "right",
                      width: "130px",
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={5} className="text-right">
                  Total:
                </td>
                <td className="text-right">
                  <strong>
                    {totalIncVatAmount
                      ? Math.abs(totalIncVatAmount).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : 0.0}
                  </strong>
                </td>
              </tr>
            </table>
          </aside>
          <section className="fot__Po__down__info">
            <div>
              <strong>Originator</strong> <span></span>
              <strong>Date</strong> <span></span>
              <strong>Signature</strong> <span></span>
            </div>
            <div>
              <strong>Approved by</strong> <span></span>
              <strong>Date</strong> <span></span>
              <strong>Signature</strong> <span></span>
            </div>
            <div>
              <strong>Accounts</strong> <span></span>
              <strong>Date</strong> <span></span>
              <strong>Signature</strong> <span></span>
            </div>
          </section>
          {/* <div>
            <p className="my-3">
              <strong>
                NOTE: This LPO is not vaild without Authorized Signature and
                Stamp
              </strong>
            </p>
            <p className="my-3">
              <strong>
                We can cancel this order if not Supplied by specified date. The
                PO is valid for 14 days with effect from Purchase order date.
                This order number should be indicated on all invoices or
                delivery notes.
              </strong>
            </p>
            <p className="underline text-center">
              <strong>Powered by TeCH23 ERP System</strong>
            </p>
          </div> */}
        </section>
      </div>
    </div>
  );
}

export default POProcessing;
